import { createSlice } from "@reduxjs/toolkit";
import groupRoles from "../data/roles.json";

export const DEFAULT_PAGING = {
  page: 0,
  pageSize: 20,
};

export const DEFAULT_SORT_MODEL = [{ field: "email", sort: "asc" }];

export const groupSlice = createSlice({
  name: "group",
  initialState: {
    filterModel: { items: [{}] },
    group: null,
    groupCodes: [],
    groupControlsLoading: 0,
    groupLoading: 0,
    paginationModel: DEFAULT_PAGING,
    roles: [...groupRoles],
    sortModel: [...DEFAULT_SORT_MODEL],
    totalSize: 0,
    users: [],
  },
  reducers: {
    setFilterModel(state, action) {
      const { payload } = action;
      state.filterModel = payload;
    },
    setGroup(state, action) {
      const { payload } = action;
      state.group = payload;
    },
    setGroupCodes(state, action) {
      const { payload } = action;
      if (Array.isArray(payload)) {
        state.groupCodes = payload;
      } else {
        console.warn(
          "GROUPSLICE:SETGROUPCODES: Group Codes must be an array of GroupCodes"
        );
      }
    },
    setGroupControlsLoading(state, action) {
      const { payload } = action;
      state.groupControlsLoading = payload;
    },
    setGroupLoading(state, action) {
      const { payload } = action;
      state.groupLoading = payload;
    },
    setPaginationModel(state, action) {
      const { payload } = action;
      state.paginationModel = payload;
    },
    setSortModel(state, action) {
      const { payload } = action;
      state.sortModel = payload;
    },
    setTotalSize(state, action) {
      const { payload } = action;
      state.totalSize = payload;
    },
    setUsers(state, action) {
      const { payload } = action;
      if (Array.isArray(payload)) {
        state.users = payload;
      }
    },
  },
});

export const {
  setFilterModel,
  setGroup,
  setGroupCodes,
  setGroupControlsLoading,
  setGroupLoading,
  setPaginationModel,
  setSortModel,
  setTotalSize,
  setUsers,
} = groupSlice.actions;

export const groupReducer = groupSlice.reducer;
