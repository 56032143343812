import { useEffect, useState } from "react";
import { TextField } from "@mui/material";

export default function FormikTextField({
  handleChange,
  handleBlur,
  value,
  error,
  touched,
  ...otherProps
}) {
  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    if(touched === undefined) {
      setIsTouched(true);
    } else {
      setIsTouched(!!touched);
    }
  }, [touched])
  return (
    <TextField
      onChange={handleChange}
      onBlur={handleBlur}
      value={value}
      error={isTouched && error != null}
      helperText={isTouched && error ? error : null}
      {...otherProps}
    />
  );
}
