import {
  Card,
  Typography,
  CardMedia,
  Tooltip,
  IconButton,
  useTheme,
  useMediaQuery,
  Box,
  Divider,
} from "@mui/material";
import Stack from "@mui/material/Stack";

import React, { useRef, useState } from "react";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { ReactComponent as CollectionsLogo } from "assets/icons/bookmark-blue-icon.svg";
import { ReactComponent as CollectionsLogoHover } from "assets/icons/bookmark-hover-icon.svg";

import { useDispatch } from "react-redux";
import {
  setCollectionId,
  setCollectionSourceId,
  setEntityName,
  setIsCitationAvailable,
  setIsSidebarOpen,
  setIsSourceSidebarOpen,
  setIsSummaryAvailable,
  setSelectedBookmarkOption,
  setSourceUrl,
  setSummaryInfoData,
} from "context/features/dataSlice";
import defaultBackground from "assets/images/image-placeholder.png";
import { HiOutlineLockOpen } from "react-icons/hi2";
import { VscLock } from "react-icons/vsc";
import { logEvent } from "services/amplitude.service.js";
import { getRegularDate } from "utils/time.util";

export const AcademiaCard = ({
  data,
  cardRef,
  category,
  query,
  academiaSearch,
  academiaLabel,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const openSourceSidebar = () => {
    logEvent("click_card_header", {
      host: academiaLabel.hostName_chr,
      entity: academiaLabel.categoryName_chr,
    });
    dispatch(setIsSourceSidebarOpen(true));
    dispatch(setSourceUrl(academiaLabel.hostName_chr));
    dispatch(setEntityName(academiaLabel.categoryName_chr));
  };

  const CardHeader = () => {
    return (
      <Stack
        mb={1}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        pt={1}
        mt={1}
        sx={{ cursor: "pointer", height: "3vh" }}
        onClick={openSourceSidebar}
      >
        <Typography
          variant="body1"
          sx={{
            fontWeight: "bold",
            width: "20rem",
            cursor: "pointer",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            color: "rgb(126,127,126)",
          }}
        >
          {academiaSearch}
        </Typography>
      </Stack>
    );
  };

  const openSidebar = () => {
    logEvent("open_bookmark_popup", { click: true, url: data.url });
    dispatch(setSelectedBookmarkOption("notes"));

    dispatch(
      setSummaryInfoData({
        publisher: academiaSearch,
        title: data.title,
        published_date: data.datePublished,
        url: data.url,
      })
    );

    dispatch(setCollectionSourceId(data.collectionSourceId));
    dispatch(setCollectionId(data.collectionId));
    dispatch(setIsCitationAvailable(data.isCitationAvailable));
    dispatch(setIsSummaryAvailable(data.isSummaryAvailable));
    dispatch(setIsSidebarOpen(true));
  };

  const BookmarkOptions = () => {
    return (
      <Stack
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          cursor: "pointer",
          mt: "auto",
          borderRadius: 1,
          "&:hover": {
            backgroundColor: "#e0f2fe",
          },
          pl: 1,
          pr: 1,
        }}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        gap={1}
        mt="auto"
      >
        {data.collectionSourceId ? (
          <CollectionsLogo
            onClick={openSidebar}
            alt="Collections"
            style={{
              width: 18,
              height: 18,
              fill: "blue",
              color: "blue",
            }}
          />
        ) : (
          <Box
            sx={{
              width: 18,
              height: 18,
              cursor: "pointer",
            }}
          >
            {isHovered && (
              <CollectionsLogoHover
                onClick={openSidebar}
                alt="Collections"
                style={{
                  fill: "blue",
                  color: "blue",
                }}
              />
            )}
          </Box>
        )}

        <IconButton
          sx={{
            p: 0,
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
          aria-label="more"
          onClick={openSidebar}
        >
          <MoreHorizIcon />
        </IconButton>
      </Stack>
    );
  };

  const NotesSection = () => {
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          mt: 1,
          gap: 2,
          justifyContent: "space-between",
        }}
      >
        <Stack alignContent="end">
          <Typography
            variant={isMobile ? "body1" : "body2"}
            sx={{
              color: "grey",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: { xs: 3 },
              WebkitBoxOrient: "vertical",
              maxHeight: { xs: "6vh" },
              lineHeight: { xs: "2vh" },
              width: "98%",
            }}
          >
            {data.datePublished && `${getRegularDate(data.datePublished)} - `}
            {data.description}
          </Typography>
          <Typography
            variant={isMobile ? "body1" : "body2"}
            sx={{
              color: "grey",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: { xs: 1 },
              WebkitBoxOrient: "vertical",
              maxHeight: { xs: "2vh" },
              lineHeight: { xs: "2vh" },
              width: "98%",
            }}
          >{`Author - ${data.authors}`}</Typography>
        </Stack>
        <BookmarkOptions />
      </Box>
    );
  };

  const handleTitleClick = () => {
    logEvent("click_resultset_title", {
      url: data.url,
      query: query,
      entity: data.entityName,
      category: category,
      mediabiasrating: data.mediaBiasRating ? data.mediaBiasRating : "NA",
    });
    window.open(data.url, "_blank");
  };

  return (
    <Card
      ref={cardRef}
      sx={{
        width: "100%",
        boxShadow: "none",
        padding: "0 1rem",
        backgroundImage: "none"
      }}
    >
      <Stack direction="row" alignItems="center">
        <Box sx={{ flexGrow: 1, flexBasis: "100%", px: 1 }}>
          <Stack direction="column" gap={1} justifyContent="space-between">
            <Box sx={{ height: "10vh" }}>
              <CardHeader />
              <Box
                sx={{
                  height: { md: "3vh", lg: "5vh" },
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "start",
                }}
              >
                <Typography
                  variant="body4"
                  onClick={handleTitleClick}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      color: "rgb(18,89,151)",
                    },
                  }}
                >
                  {data.title}
                </Typography>
                {data.hasPayWall && data.isPartner ? (
                  <Tooltip title="We removed the paywall for you!">
                    <Box sx={{ cursor: "pointer" }}>
                      <HiOutlineLockOpen />
                    </Box>
                  </Tooltip>
                ) : (
                  data.hasPayWall && (
                    <Tooltip
                      placement="bottom-start"
                      title="This source may limit your access without a subscription"
                    >
                      <Box sx={{ cursor: "pointer" }}>
                        <VscLock />
                      </Box>
                    </Tooltip>
                  )
                )}
              </Box>
            </Box>
          </Stack>
          <NotesSection />
          <Divider sx={{ mt: "1vh" }} />
        </Box>
      </Stack>
    </Card>
  );
};
