import React, { useState } from "react";
import {
  Box,
  Stack,
  Typography,
  useTheme,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  TextField,
  Button,
  Popover,
  Tooltip,
} from "@mui/material";
import { MoreHorizOutlined } from "@mui/icons-material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import SwapVertOutlinedIcon from "@mui/icons-material/SwapVertOutlined";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import LanguageIcon from "@mui/icons-material/Language";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useSelector } from "react-redux";
import { getRegularDate } from "utils/time.util";
import MediaBiasChart from "./BarGraph";
import ProfileAvatar from "components/base/ProfileAvatar";
import GroupAvatar from "./GroupAvatar";
import { useIsTab } from "hooks/is-tab.hook";
import ClearIcon from "@mui/icons-material/Clear";
import { toast } from "react-toastify";

const Header = ({
  isPublic,
  collection,
  length,
  setAccessPopup,
  setFilter,
  filter,
  setEdit,
  setDeletePopup,
  collectionOverview,
  setName,
  onSort,
  sortCriteria,
  sortDirection,
}) => {
  const [anchorMoreMenu, setAnchorMoreMenu] = useState(null);
  const [anchorSortMenu, setAnchorSortMenu] = useState(null);
  const [showSearch, setShowSearch] = useState(false);

  const theme = useTheme();
  const isTab = useIsTab();
  const { user } = useSelector((state) => state.auth);

  const handleMoreMenuOpen = (event) => setAnchorMoreMenu(event.currentTarget);
  const handleMoreMenuClose = () => setAnchorMoreMenu(null);

  const handleSortMenuOpen = (event) => setAnchorSortMenu(event.currentTarget);
  const handleSortMenuClose = () => setAnchorSortMenu(null);

  const handleMenuItemClick = (action) => {
    setAnchorMoreMenu(null);
    switch (action) {
      case "manageAccess":
        setAccessPopup(true);
        break;
      case "rename":
        setEdit(true);
        break;
      case "delete":
        setDeletePopup(true);
        break;
      default:
        break;
    }
  };

  const handleSort = (criteria) => {
    onSort(criteria);
    handleSortMenuClose();
  };

  const isPopoverOpen = Boolean(anchorMoreMenu);

  const getSortIcon = (criteria) => {
    if (sortCriteria === criteria) {
      return sortDirection === "asc" ? (
        <ArrowUpwardIcon fontSize="small" />
      ) : (
        <ArrowDownwardIcon fontSize="small" />
      );
    }
    return null;
  };

  const getUsername = () => {
    if (collection.isShared_ysn) {
      return collectionOverview.collectionSharedWithUsersDisplay;
    }
    if (isPublic) {
      return collectionOverview.userName_chr;
    }

    return user.userName;
  };

  const handleCopyToClipboard = () => {
    const currentUrl = window.location.href;
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        console.log("Link copied to clipboard");
      })
      .catch((error) => {
        console.error("Failed to copy the link:", error);
      });
    toast.success(
      `Link to ${collection.collectionName_chr} copied to clipboard!`
    );
  };

  return (
    <React.Fragment>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {!isPublic ? (
          <Stack direction="row" gap={2} alignItems={"center"}>
            <Typography
              color="#5c6d7e"
              alignItems="center"
              variant="h3"
              fontSize={"3rem"}
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                lineHeight: "3rem",
                maxHeight: "6rem",
                maxWidth: "95%",
                flexShrink: 0,
              }}
            >
              {collection.collectionName_chr}
            </Typography>
            {collection.isShared_ysn && (
              <IconButton sx={{ p: 0, height: 18, width: 18 }}>
                <PeopleOutlineIcon />
              </IconButton>
            )}
            {collection.isPublic_ysn && (
              <IconButton sx={{ p: 0, height: 18, width: 18 }}>
                <LanguageIcon />
              </IconButton>
            )}
            <IconButton
              sx={{ p: 0, height: 18, width: 18 }}
              onClick={handleMoreMenuOpen}
            >
              <MoreHorizOutlined />
            </IconButton>
            <Popover
              anchorEl={anchorMoreMenu}
              open={isPopoverOpen}
              onClose={handleMoreMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              disableScrollLock={true}
            >
              {collection.parentCollectionId === 0 && (
                <MenuItem
                  sx={{
                    display: "flex",
                    gap: 2,
                    p: 1.5,
                    mt: 2,
                    width: "22rem",
                    "&:hover": {
                      backgroundColor: theme.palette.secondary.main,
                    },
                  }}
                  onClick={() => handleMenuItemClick("manageAccess")}
                >
                  <PeopleOutlineIcon
                    sx={{ color: theme.palette.primary.main }}
                  />
                  <Typography variant="body1">Manage Access</Typography>
                </MenuItem>
              )}
              <MenuItem
                sx={{
                  display: "flex",
                  gap: 2,
                  p: 1.5,
                  width: "22rem",
                  mt: collection.parentCollectionId !== 0 ? 2 : 0,
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.main,
                  },
                }}
                onClick={() => {
                  setName(collection.collectionName_chr);
                  handleMenuItemClick("rename");
                }}
              >
                <ModeEditOutlineOutlinedIcon
                  sx={{ color: theme.palette.primary.main }}
                />
                <Typography>Rename</Typography>
              </MenuItem>
              <MenuItem
                sx={{
                  display: "flex",
                  gap: 2,
                  p: 1.5,
                  mb: 2,
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.main,
                  },
                }}
                onClick={() => handleMenuItemClick("delete")}
              >
                <DeleteOutlineIcon sx={{ color: theme.palette.primary.main }} />
                <Typography>Delete</Typography>
              </MenuItem>
            </Popover>
          </Stack>
        ) : (
          <Stack direction="row" justifyContent="space-between">
            <Stack alignItems="center" direction="row" gap={4}>
              <Typography variant="h3" color={"#5c6d7e"} fontSize={"3rem"}>
                {collection.collectionName_chr}
              </Typography>
              <LanguageIcon />
              <Tooltip title="Share this link">
                <Button
                  onClick={handleCopyToClipboard}
                  startIcon={<InsertLinkIcon />}
                  variant="contained"
                  color="secondary"
                  sx={{
                    px: 2,
                  }}
                >
                  Share
                </Button>
              </Tooltip>
            </Stack>
          </Stack>
        )}
        <Stack direction="row" gap={2}>
          {showSearch ? (
            <TextField
              value={filter}
              onBlur={() => setShowSearch(false)}
              placeholder="Search collections..."
              onChange={(e) => setFilter(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      sx={{ height: 30, color: theme.palette.primary.dark }}
                    />
                  </InputAdornment>
                ),
                endAdornment: filter && (
                  <InputAdornment position="end">
                    <ClearIcon
                      sx={{ cursor: "pointer", height: 30 }}
                      onClick={() => setFilter("")}
                    />
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            <IconButton sx={{ p: 0 }} onClick={() => setShowSearch(true)}>
              <SearchOutlinedIcon sx={{ color: theme.palette.primary.dark }} />
            </IconButton>
          )}
          <IconButton sx={{ p: 0 }} onClick={handleSortMenuOpen}>
            <SwapVertOutlinedIcon sx={{ color: theme.palette.primary.dark }} />
          </IconButton>
          <Menu
            anchorEl={anchorSortMenu}
            open={Boolean(anchorSortMenu)}
            onClose={handleSortMenuClose}
          >
            <MenuItem onClick={() => handleSort("dateAdded")}>
              <ListItemIcon>{getSortIcon("dateAdded")}</ListItemIcon>
              Date Added
            </MenuItem>
            <MenuItem onClick={() => handleSort("publishedDate")}>
              <ListItemIcon>{getSortIcon("publishedDate")}</ListItemIcon>
              Published Date
            </MenuItem>
            <MenuItem onClick={() => handleSort("newsGuardRating")}>
              <ListItemIcon>{getSortIcon("newsGuardRating")}</ListItemIcon>
              News Guard Rating
            </MenuItem>
            <MenuItem onClick={() => handleSort("title")}>
              <ListItemIcon>{getSortIcon("title")}</ListItemIcon>
              Title
            </MenuItem>
            <MenuItem onClick={() => handleSort("source")}>
              <ListItemIcon>{getSortIcon("source")}</ListItemIcon>
              Source
            </MenuItem>
          </Menu>
        </Stack>
      </Stack>

      <Box
        sx={{
          backgroundColor: theme.palette.secondary.main,
          p: 2,
          my: 4,
          borderRadius: 1,
          display: "grid",
          gridTemplateAreas: !isTab
            ? '"owners divide ratings" "stats divide ratings"'
            : '"owners" "stats" "divide" "ratings"',
          gridTemplateColumns: !isTab ? "1fr auto 1fr" : "1fr",
          width: {
            xs: "90vw",
            sm: "unset",
          },
          gap: 2,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            gridArea: "owners",
          }}
        >
          {collection.isShared_ysn ? (
            <GroupAvatar
              userIds={collectionOverview.collectionSharedWithUserId}
            />
          ) : (
            <ProfileAvatar
              userId={isPublic ? collectionOverview.userId_chr : user.userId}
            />
          )}
          <Typography variant="h6">{getUsername()}</Typography>
        </Stack>

        <Stack
          direction="row"
          gap={3}
          sx={{
            gridArea: "stats",
          }}
        >
          <Stack gap={1}>
            <Typography variant="body1" color="grey">
              Bookmarks
            </Typography>
            <Typography variant="body1">{length}</Typography>
          </Stack>
          <Stack gap={1}>
            <Typography variant="body1" color="grey">
              Created
            </Typography>
            <Typography variant="body1">
              {getRegularDate(collectionOverview.createdDateTime_dtm)}
            </Typography>
          </Stack>
          <Stack gap={1}>
            <Typography variant="body1" color="grey">
              Updated
            </Typography>
            <Typography variant="body1">
              {getRegularDate(collectionOverview.lastUpdatedDateTime_dtm)}
            </Typography>
          </Stack>
        </Stack>
        <Divider
          flexItem
          orientation={isTab ? "horizontal" : "vertical"}
          sx={{
            gridArea: "divide",
          }}
        />

        <MediaBiasChart allSidesData={collectionOverview.allSidesData} />
      </Box>
    </React.Fragment>
  );
};

export default Header;
