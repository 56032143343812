export const categoryDescriptions = {
  "Global News":
    "Sooth’s Global News category includes major print and online news and information outlets covering national and international news with a strong emphasis on daily original reporting. The majority of Sooth’s Global News sources have been rated by NewsGuard. Global News does not include any sources with a Trust Score below 60 (out of 100), per NewsGuard’s rating system.",

  "Other Selected Sources":
    "Sooth’s Other Selected Sources category includes a wide range of sources that do not adequately fit into one of the other categories. These include consulting firms, financial institutions, trade associations, and many other types of organizations.",

  "Think Tanks":
    "Sooth’s Think Tanks category includes leading national and international think tanks covering a broad range of topics. The majority of all Think Tanks have been ranked by the Think Tanks and Civil Societies Program at the Lauder Institute, University of Pennsylvania. Think tanks, as defined by the Lauder Institute, are public policy research and engagement organizations that generate policy-oriented research, analysis, and advice on domestic and international issues that enable the public and policymakers to make informed decisions regarding public policy. Think tanks may be affiliated or independent institutions and are structured as permanent bodies.",

  "Periodicals & Specialty News":
    "Sooth’s Periodicals & Specialty News category includes daily, weekly, and monthly print and online news and information outlets covering both broad news topics and industry-specific news with a strong emphasis on original reporting. The majority of Sooth’s Periodicals & Specialty News sources have been rated by NewsGuard. Periodicals & Specialty News does not include any sources with a Trust Score below 60 (out of 100), per NewsGuard’s rating system.",

  "Intergovernmental Organizations":
    "Sooth’s Intergovernmental Organizations (IGO) category includes organizations whose members are composed primarily of sovereign states.",

  "US Government":
    "Sooth’s U.S. Government category includes U.S. federal government websites and official state sites.",

  "Academia & Scholarly Resources":
    "Sooth’s Academia category includes leading U.S. and non-U.S. colleges and universities and their affiliated research centers and publications. This category also includes numerous online research repositories.",
  books: "Books",
  wiki: "Encyclopedia & Dictionary",
};
