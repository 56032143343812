import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { getFormattedDate } from "utils/time.util";
import { MoreHoriz } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Table({ publicCollections, setCurrentPublicCollection }) {
  const navigate = useNavigate();

  const columns = [
    {
      field: "lastUpdatedDateTime_dtm",
      headerName: (
        <Typography
          variant="body3"
          sx={{ color: "#425a6a", fontWeight: "bold" }}
        >
          Updated
        </Typography>
      ),
      flex: 0.5,
      sortable: true,
      type: "date",
      valueGetter: (params) => {
        return params.value ? new Date(params.value) : null;
      },
      renderCell: (params) => (
        <Typography variant="body3" sx={{ color: "#6c6c6c" }}>
          {getFormattedDate(params.value)}
        </Typography>
      ),
    },
    {
      field: "collectionName_chr",
      headerName: (
        <Typography variant="body3" sx={{ color: "#6c6c6c" }}>
          Collection Name
        </Typography>
      ),
      flex: 1,
      sortable: true,
      renderCell: (params) => (
        <Typography
          onClick={() => {
            setCurrentPublicCollection(params.row);
            navigate(
              `/collections/public/${params.row.publicCollectionID_chr}`
            );
          }}
          sx={{ cursor: "pointer" }}
          fontSize={"1.4rem"}
        >
          {params.value}
        </Typography>
      ),
    },

    {
      field: "userName_chr",
      headerName: (
        <Typography variant="body3" sx={{ color: "#6c6c6c" }}>
          Owner
        </Typography>
      ),
      flex: 0.5,
      sortable: false,
      renderCell: (params) => (
        <Typography fontSize={"1.4rem"}>{params.value}</Typography>
      ),
    },
    {
      field: "publicCollectionSource_Count_int",
      headerName: (
        <Typography variant="body3" sx={{ color: "#6c6c6c" }}>
          Source Count
        </Typography>
      ),
      flex: 0.5,
      sortable: true,
      renderCell: (params) => (
        <Typography fontSize={"1.4rem"}>{params.value}</Typography>
      ),
    },
    // {
    //   field: "follower_Count_int",
    //   headerName: (
    //     <Typography variant="body3" sx={{ color: "#6c6c6c" }}>
    //       Followers
    //     </Typography>
    //   ),
    //   flex: 0.8,
    //   sortable: false,
    //   renderCell: (params) => (
    //     <Typography fontSize={"1.4rem"}>{params.value}</Typography>
    //   ),
    // },
  ];
  return (
    <Box
      sx={{ minWidth: 400, overflowX: "auto", height: "100%", width: "100%" }}
    >
      <DataGrid
        rows={publicCollections}
        columns={columns}
        pageSize={5}
        sx={{
          border: "none",
          overflow: "hidden",
          width: "100%",
          margin: "0 auto",
          "& .MuiDataGrid-cell:focus-within": {
            backgroundColor: "transparent",
            outline: "none",
          },
          "& .MuiDataGrid-row.Mui-selected": {
            backgroundColor: "#e1e5e8",
            color: "black",
          },
          "& .MuiDataGrid-row.Mui-selected:hover": {
            backgroundColor: "#e1e5e8",
            color: "black",
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "#e1e5e8",
            color: "black",
            cursor: "pointer",
          },
        }}
        getRowId={(row) => row.publicCollectionID_chr}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 25 },
          },
        }}
        pageSizeOptions={[25, 50]}
        hideFooterSelectedRowCount
        onRowClick={(params) => {
          setCurrentPublicCollection(params.row);
          navigate(`/collections/public/${params.row.publicCollectionID_chr}`);
        }}
      />
    </Box>
  );
}

export default Table;
