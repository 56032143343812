import { startsWith, get, trimStart } from "lodash";

import { Group, GroupCode, GroupUser } from "../models";

import { subscriptionsAPI } from "services/apis.service";

export class GroupService {
  #axios;
  constructor(axiosInstance) {
    this.#axios = axiosInstance;
  }

  async getGroup() {
    try {
      const { data } = await this.#axios.get(subscriptionsAPI.getGroupData, {
        cache: false,
      });
      return new Group(data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getUsers(page, pageSize, filterModel, sortModel) {
    let filterValue = get(filterModel, "value", null);
    if (startsWith(filterValue, "@") && filterModel.field === "userName") {
      filterValue = trimStart(filterValue, "@");
    }
    try {
      const { data } = await this.#axios.post(
        subscriptionsAPI.getGroupUsers,
        {
          paginate: true,
          pageIndex: page + 1,
          pageSize,
          filterField: get(filterModel, "field", null),
          filterOperator: get(filterModel, "operator", null),
          filterValue,
          sortField: get(sortModel, "field", null),
          sortOrder: get(sortModel, "sort", null),
        },
        {
          cache: false,
        }
      );
      if (data == null) {
        return {
          users: [],
          page,
          pageSize,
          total: 0,
        };
      }
      const users = data.users.map((user) => new GroupUser(user));
      return {
        users,
        total: data.pagination.totalCount,
        page: data.pagination.pageIndex - 1,
        pageSize: data.pagination.pageSize,
      };
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getGroupCodes() {
    try {
      const response = await this.#axios.get(subscriptionsAPI.getGroupCodes, {
        cache: false,
      });
      return response.data.map((code) => new GroupCode(code));
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async createGroupCode() {
    try {
      const response = await this.#axios.post(
        subscriptionsAPI.createGroupCode,
        {},
        { cache: false }
      );
      return new GroupCode(response.data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async deleteGroupCode(groupCodeId) {
    try {
      await this.#axios.delete(
        `${subscriptionsAPI.deleteGroupCode}?codeId=${groupCodeId}`
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async joinGroupCode(code) {
    try {
      const response = await this.#axios.post(
        subscriptionsAPI.joinGroupWithCode,
        {
          code,
        },
        {
          cache: false,
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      if (error.response.data) {
        return {
          status: "error",
          error: error.response.data,
        };
      }
      return {
        status: "error",
        error,
      };
    }
  }
}
