export const individualBasePricing = {
  k12: {
    yearly: 40,
    monthly: 4,
  },
  highEd: {
    yearly: 60,
    monthly: 6,
  },
  gov: {
    yearly: 80,
    monthly: 8
  }
};
