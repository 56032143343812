import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getFeedCategories } from "../services/news-feed.services";

export const useGetFeedCategories = () => {
  const { user } = useSelector((state) => state.auth);
  const [feedCategories, setFeedCategories] = useState([]);

  const fetchCategories = async () => {
    try {
      const categories = await getFeedCategories(user.token);
      setFeedCategories(categories);
    } catch (error) {
      console.error("Failed to fetch feed categories:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return { feedCategories };
};
