import { filter, find, map } from "lodash";
import { Fragment, forwardRef, useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  CheckCircle,
  CircleOutlined,
  DeleteOutline,
  KeyOutlined,
  ManageAccountsOutlined,
  RemoveCircle,
  ShieldOutlined,
  VerifiedUserOutlined,
} from "@mui/icons-material";
import {
  DataGrid,
  getGridStringOperators,
  getGridSingleSelectOperators,
  gridClasses,
  GridActionsCellItem,
  GridCellModes,
} from "@mui/x-data-grid";

import { useGroup } from "../hooks";
import { DEFAULT_SORT_MODEL } from "../context";
import { getFormattedDate } from "utils/time.util";

import GenericDialog from "components/base/GenericDialog";

const SmallCheckBox = forwardRef(function SmallCheckBox(props, ref) {
  return (
    <Checkbox
      icon={<CircleOutlined />}
      checkedIcon={<CheckCircle />}
      indeterminateIcon={<RemoveCircle />}
      size="small"
      ref={ref}
      {...props}
    />
  );
});

const slots = {
  baseCheckbox: SmallCheckBox,
};

const slotProps = {
  baseTooltip: {
    followCursor: true,
  },
};

const initialState = {
  sorting: {
    sortModel: [...DEFAULT_SORT_MODEL],
  },
};

export default function GroupUserTable({ sx = {} }) {
  const { palette, typography } = useTheme();
  const {
    group,
    groupRoles,
    paginationModel,
    setFilterModel,
    setPaginationModel,
    setSortModel,
    sortModel,
    totalSize,
    users,
    usersLoading,
    removeUsersFromGroup,
    updateUsersRole,
    updateUsersLicenseLevel,
  } = useGroup();

  const { user } = useSelector((state) => state.auth);

  const [selectedRows, setSelectedRows] = useState([]);
  const [roleModal, setRoleModal] = useState(false);
  const [confirmRemoveModal, setConfirmRemoveModal] = useState(false);
  const [licenseLevelModal, setLicenseLevelModal] = useState(false);
  const [changeLicenseLevel, setChangeLicenseLevel] = useState("");
  const [changeRole, setChangeRole] = useState("");
  const [cellModesModel, setCellModesModel] = useState({});

  function handleFilterModelChange(model) {
    setFilterModel(model);
  }

  function handleSortModelChange(model) {
    setSortModel(model);
  }

  function handleRowSelectionChange(model) {
    setSelectedRows(model);
  }

  async function handleRemoveUsers(selectedUsers) {
    const success = await removeUsersFromGroup(selectedUsers);
    if (success) {
      toast.success(
        `Successfully removed user${selectedUsers.length > 1 ? "s" : ""}.`
      );
    }
  }

  const handleCellClick = useCallback((params, event) => {
    if (!params.isEditable) {
      return;
    }

    // Ignore portal
    if (
      event.target.nodeType === 1 &&
      !event.currentTarget.contains(event.target)
    ) {
      return;
    }

    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  const handleCellModesModelChange = useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  const gridSx = useMemo(
    () => ({
      border: "none",
      maxWidth: "100vw",
      [`& .${gridClasses.cell}:not(.${gridClasses["cell--editable"]}):focus, & .${gridClasses.cell}:not(.${gridClasses["cell--editable"]}):focus-within`]:
        {
          outline: "none",
        },
      [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
        {
          outline: "none",
        },
      [`& .${gridClasses.cell}.${gridClasses["cell--editable"]}:hover`]: {
        outlineStyle: "solid",
        outlineColor: palette.primary.main,
        outlineWidth: "1px",
        outlineOffset: "-1px",
      },
    }),
    [palette]
  );

  const columns = [
    {
      field: "email",
      filterOperators: filter(
        getGridStringOperators(),
        ({ value }) =>
          value !== "isAnyOf" && value !== "isNotEmpty" && value !== "isEmpty"
      ),
      renderCell({ api, id, value }) {
        const { confirmed } = api.getRow(id);
        return (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            maxWidth="100%"
            minWidth="100%"
            gap={0}
          >
            <Tooltip followCursor title={value}>
              <Typography
                variant="caption"
                noWrap
                sx={{
                  flex: 11,
                  mr: "3px",
                }}
              >
                {value}
              </Typography>
            </Tooltip>
            {confirmed ? (
              <Tooltip title="verified">
                <VerifiedUserOutlined
                  sx={{
                    flex: 1,
                    color: palette.grey[500],
                    fontSize: typography.body1.fontSize,
                    "&:hover": {
                      color: palette.success.main,
                    },
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="unverified">
                <ShieldOutlined
                  sx={{
                    flex: 1,
                    color: palette.grey[500],
                    fontSize: typography.body1.fontSize,
                    "&:hover": {
                      color: palette.warning.main,
                    },
                  }}
                />
              </Tooltip>
            )}
          </Stack>
        );
      },

      flex: 3,
      headerName: "Email",
      type: "string",
    },
    {
      field: "userName",
      filterOperators: filter(
        getGridStringOperators(),
        ({ value }) =>
          value !== "isAnyOf" && value !== "isNotEmpty" && value !== "isEmpty"
      ),
      flex: 3,
      headerName: "Username",
      type: "string",
      renderCell({ id, value }) {
        return id === user.userId ? (
          <Stack direction="row" alignItems="center" maxWidth="100%">
            <Tooltip followCursor title={value}>
              <Typography variant="caption" noWrap sx={{ flex: 11 }}>
                @{value}
              </Typography>
            </Tooltip>
            <Chip size="small" color="info" variant="outlined" label="You" />
          </Stack>
        ) : (
          <Tooltip followCursor title={value}>
            <Typography variant="caption" maxWidth="100%" noWrap>
              @{value}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      filterable: false,
      field: "registerationDate",
      headerName: "Created",
      flex: 1.5,
      valueFormatter({ value }) {
        return getFormattedDate(value);
      },
    },
    {
      filterable: false,
      field: "lastLoginTime",
      headerName: "Last Login",
      flex: 1.5,
      valueFormatter({ value }) {
        if (value == null) {
          return "never";
        }
        return getFormattedDate(value);
      },
    },
    {
      field: "groupLicenseKey",
      filterOperators: filter(
        getGridSingleSelectOperators(),
        ({ value }) => value !== "isAnyOf"
      ),
      flex: 1.5,
      headerName: "License",
      type: "singleSelect",
      valueFormatter({ value }) {
        const level = find(group.licenseLevels, { key: value });
        if (level == null) {
          return "Unknown";
        }
        return level.label;
      },
      valueOptions: map(
        filter(
          group.licenseLevels,
          (level) => level.key.toLowerCase() !== "unknown"
        ),
        (level) => ({
          value: level.key,
          label: level.label,
        })
      ),
      editable: true,
    },
    {
      field: "roleName",
      filterOperators: filter(
        getGridSingleSelectOperators(),
        ({ value }) => value !== "isAnyOf"
      ),
      flex: 1.5,
      headerName: "Role",
      type: "singleSelect",
      valueFormatter({ value }) {
        const role = find(groupRoles, { key: value });
        if (role == null) {
          return "Unknown";
        }
        return role.label;
      },
      valueOptions: map(groupRoles, "key"),
      editable: true,
    },
    {
      field: "actions",
      flex: 0.1,
      getActions({ id }) {
        return [
          <GridActionsCellItem
            icon={
              <Tooltip followCursor title="Remove User">
                <DeleteOutline fontSize="inherit" />
              </Tooltip>
            }
            label="Remove"
            disabled={id === user.userId}
            onClick={() => {
              handleRemoveUsers([id]);
            }}
          />,
        ];
      },
      type: "actions",
    },
  ];
  return (
    <Box
      sx={{
        height: "100%",
        ...sx,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          "& > button": {
            transition: "all 0.3s ease",
            opacity: selectedRows.length > 1 ? 1 : 0,
            visibility: selectedRows.length > 1 ? "visible" : "hidden",
          },
        }}
      >
        <IconButton
          size="small"
          onClick={() => {
            setConfirmRemoveModal(true);
          }}
        >
          <Tooltip title="Remove Accounts">
            <DeleteOutline fontSize="inherit" />
          </Tooltip>
        </IconButton>
        <GenericDialog
          isOpen={confirmRemoveModal}
          handleClose={() => {
            setConfirmRemoveModal(false);
          }}
          title={`Remove User${selectedRows.length > 0 ? "s" : ""} from Group?`}
          actions={
            <Fragment>
              <Button
                variant="outlined"
                onClick={() => {
                  setConfirmRemoveModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleRemoveUsers(selectedRows);
                  setConfirmRemoveModal(false);
                }}
              >
                Remove Users
              </Button>
            </Fragment>
          }
        >{`You are about to remove multiple users from your group. Are you sure you want to remove ${
          selectedRows.length
        } user${selectedRows.length > 1 ? "s" : ""}?`}</GenericDialog>
        <IconButton
          size="small"
          onClick={() => {
            setRoleModal(true);
          }}
        >
          <Tooltip title="Change Account Roles">
            <ManageAccountsOutlined fontSize="inherit" />
          </Tooltip>
        </IconButton>
        <GenericDialog
          isOpen={roleModal}
          handleClose={() => {
            setRoleModal(false);
          }}
          title={`Change User${selectedRows.length > 1 ? "s'" : "'s"} Role?`}
          actions={
            <Fragment>
              <Button
                variant="outlined"
                onClick={() => {
                  setRoleModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  updateUsersRole(selectedRows, changeRole, true);
                  setRoleModal(false);
                  setChangeRole("");
                }}
                disabled={changeRole === ""}
              >
                Change Role
              </Button>
            </Fragment>
          }
        >
          <Stack direction="column">
            <Typography>
              What role do you want to change {selectedRows.length} users to?
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="role-label">Role</InputLabel>
              <Select
                labelId="role-label"
                label="Role"
                value={changeRole}
                onChange={(event) => {
                  setChangeRole(event.target.value);
                }}
              >
                {groupRoles.map((level) => {
                  return (
                    <MenuItem key={level.key} value={level.key}>
                      {level.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Stack>
        </GenericDialog>
        <IconButton size="small" onClick={() => setLicenseLevelModal(true)}>
          <Tooltip title="Change License Level">
            <KeyOutlined fontSize="inherit" />
          </Tooltip>
        </IconButton>
        <GenericDialog
          isOpen={licenseLevelModal}
          handleClose={() => {
            setLicenseLevelModal(false);
          }}
          title="Change Users' License Level?"
          actions={
            <Fragment>
              <Button
                variant="outlined"
                onClick={() => {
                  setLicenseLevelModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  updateUsersLicenseLevel(
                    selectedRows,
                    changeLicenseLevel,
                    true
                  );
                  setLicenseLevelModal(false);
                  setChangeLicenseLevel("");
                }}
                disabled={changeLicenseLevel === ""}
              >
                Change Level
              </Button>
            </Fragment>
          }
        >
          <Stack direction="column">
            <Typography>
              What license level do you want to change {selectedRows.length}{" "}
              users to?
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="license-level-label">License Level</InputLabel>
              <Select
                labelId="license-level-label"
                label="License Level"
                value={changeLicenseLevel}
                onChange={(event) => {
                  setChangeLicenseLevel(event.target.value);
                }}
              >
                {group.licenseLevels.map((level) => {
                  return (
                    <MenuItem key={level.key} value={level.key}>
                      {level.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Stack>
        </GenericDialog>
      </Stack>
      <DataGrid
        cellModesModel={cellModesModel}
        checkboxSelection
        columns={columns}
        columnVisibilityModel={{
          gradeLicenseLevel: group && !group.isHigherEd,
        }}
        density="compact"
        filterMode="server"
        getRowId={(user) => user.id}
        initialState={initialState}
        isCellEditable={({ row, field }) =>
          row.id !== user.userId || field === "groupLicenseKey"
        }
        isRowSelectable={({ row }) => {
          return row.id !== user.userId;
        }}
        loading={usersLoading}
        onCellClick={handleCellClick}
        onCellModesModelChange={handleCellModesModelChange}
        onFilterModelChange={handleFilterModelChange}
        onPaginationModelChange={(model) => setPaginationModel(model)}
        onRowSelectionModelChange={handleRowSelectionChange}
        onSortModelChange={handleSortModelChange}
        pageSizeOptions={[20, 40, 80]}
        paginationMode="server"
        paginationModel={paginationModel}
        processRowUpdate={async (updatedRow, originalRow) => {
          let roleUpdate = true;
          let licenseUpdate = true;
          if (updatedRow.roleName !== originalRow.roleName) {
            roleUpdate = await updateUsersRole(
              [updatedRow.id],
              updatedRow.roleName
            );
          }
          if (updatedRow.groupLicenseKey !== originalRow.groupLicenseKey) {
            licenseUpdate = await updateUsersLicenseLevel(
              [updatedRow.id],
              updatedRow.groupLicenseKey
            );
          }
          if (roleUpdate && licenseUpdate) {
            return updatedRow;
          }
        }}
        rowCount={totalSize}
        rows={users}
        rowSelectionModel={selectedRows}
        slots={slots}
        slotProps={slotProps}
        sortingMode="server"
        sx={gridSx}
      />
    </Box>
  );
}
