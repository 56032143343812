import dayjs from "lib/dayjs";
import { Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { useSubscriptions, useUserData } from "features/User";
import { fillParent, scrollingBox, footerFlex } from "utils/base-styles";
import PageTitle from "components/layout/PageTitle";
import Footer from "components/layout/Footer";

export default function PaymentStatusPage() {
  const [searchParams] = useSearchParams();
  const { getCheckoutSessionStatus } = useSubscriptions();
  const { getSubscriptionInfo } = useUserData();

  const [session, setSession] = useState(null);

  useEffect(() => {
    async function fetchData(sessionId) {
      getSubscriptionInfo();
      const session = await getCheckoutSessionStatus(sessionId);
      setSession(session);
    }

    if (
      searchParams.has("sessionId") &&
      (session === null || session.id !== searchParams.get("sessionId"))
    ) {
      fetchData(searchParams.get("sessionId"));
    }
  }, [searchParams, session]);

  return (
    <Box
      sx={{
        ...fillParent,
        ...scrollingBox,
        ...footerFlex,
      }}
    >
      <Stack direction="column">
        <PageTitle>Payment Confirmation</PageTitle>
        {session && session.status === "complete" ? (
          <Fragment>
            <Typography variant="body2">
              Thank you for subscribing to Sooth! We appreciate your patronage.
              For your convenience your transaction is listed below.
            </Typography>
            <TableContainer component={Paper} variant="outlined" square>
              <Table
                sx={{
                  "& .MuiTableCell-root": {
                    borderBottom: "none",
                    py: 1,
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell align="right">Quantity</TableCell>
                    <TableCell align="right">Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {session.lineItems.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.description}</TableCell>
                      <TableCell align="right">{item.quantity}</TableCell>
                      <TableCell align="right">
                        ${item.subtotal.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell rowSpan={4}>
                      <Typography variant="table" fontWeight="bold">
                        Purchase Date:
                      </Typography>{" "}
                      {dayjs(session.created).format("ll")}
                    </TableCell>
                    <TableCell align="right">Subtotal</TableCell>
                    <TableCell align="right">
                      ${session.subtotal.toFixed(2)}
                    </TableCell>
                  </TableRow>
                  {session.isDiscounted && (
                    <TableRow>
                      <TableCell align="right">Discount</TableCell>
                      <TableCell align="right">
                        <Typography
                          component="span"
                          variant="table"
                          color="error"
                        >
                          $-{session.discount.toFixed(2)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell align="right">Total</TableCell>
                    <TableCell align="right">
                      ${session.total.toFixed(2)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right">Amount Due</TableCell>
                    <TableCell align="right">
                      $
                      {session.isPaid
                        ? (0).toFixed(2)
                        : session.total.toFixed(2)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Fragment>
        ) : (
          <Typography>Session not found</Typography>
        )}
      </Stack>
      <Footer />
    </Box>
  );
}
