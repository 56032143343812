import { subscriptionsAPI } from "services/apis.service";
import {
  StripeCheckoutSession,
  StripeCheckoutSessionStatus,
  StripeCoupon,
  StripeProduct,
} from "../models";

export class SubscriptionService {
  #axios;

  constructor(axiosInstance) {
    this.#axios = axiosInstance;
  }

  logError = (error) => {
    console.error(error);
    throw error;
  };

  async getProducts() {
    try {
      const response = await this.#axios.get(subscriptionsAPI.getAllProducts);
      return response.data.map((product) => new StripeProduct(product));
    } catch (error) {
      this.logError(error);
    }
  }

  async getDefaultCoupons() {
    try {
      const response = await this.#axios.get(subscriptionsAPI.getAllCoupons);
      return response.data.map((coupon) => new StripeCoupon(coupon));
    } catch (error) {
      this.logError(error);
    }
  }

  async checkPromoCode(productId, promoCode) {
    try {
      const response = await this.#axios.post(
        subscriptionsAPI.checkPromoCode,
        {
          promoCode,
          productId,
        },
        {
          cache: false,
        }
      );

      return response.data.couponId != null
        ? new StripeCoupon(response.data)
        : null;
    } catch (error) {
      this.logError(error);
    }
  }

  async createCheckoutSession(productId, couponId, mode) {
    try {
      const response = await this.#axios.post(
        subscriptionsAPI.createCheckoutSession,
        {
          productId,
          couponId,
          mode,
          returnUrl:
            window.location.origin +
            "/profile/subscription/payment-status?sessionId=",
        },
        { cache: false }
      );
      return new StripeCheckoutSession(response.data);
    } catch (error) {
      this.logError(error);
    }
  }

  async expireCheckoutSession(sessionId) {
    try {
      await this.#axios.post(
        subscriptionsAPI.expireCheckoutSession,
        {
          checkoutSessionId: sessionId,
        },
        {
          cache: false,
        }
      );
      return true;
    } catch (error) {
      this.logError(error);
    }
  }

  async getCheckoutSessionStatus(sessionId) {
    try {
      const response = await this.#axios.post(
        subscriptionsAPI.checkoutSessionStatus,
        {
          checkoutSessionId: sessionId,
        },
        { cache: false }
      );
      return new StripeCheckoutSessionStatus(response.data, sessionId);
    } catch (error) {
      this.logError(error);
    }
  }

  async cancelSubscription(subId) {
    try {
      const response = await this.#axios.post(
        subscriptionsAPI.cancelSubscription,
        {
          subscriptionId: subId,
        },
        {
          cache: false,
        }
      );
      return response.data;
    } catch (error) {
      this.logError(error);
    }
  }

  async getIndividualSubscriptionData() {
    try {
      const response = await this.#axios.get(
        subscriptionsAPI.individualSubscriptionData,
        { cache: false }
      );
      return response.data;
    } catch (error) {
      this.logError(error);
    }
  }

  async getPortalSession() {
    try {
      const response = await this.#axios.post(
        subscriptionsAPI.createPortalSession,
        {
          returnUrl: window.location.origin + "/profile/subscription",
        },
        { cache: false }
      );
      return response.data ? response.data.url : null;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
