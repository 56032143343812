import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { Typography, Stack, Link, Tooltip, Divider } from "@mui/material";
import { useAuth } from "hooks/use-auth.hook";
import ProfileAvatar from "components/base/ProfileAvatar";
import SideNavLink from "components/base/SideNavLink";
import { useIsMobile } from "hooks/use-breakpoint-checks.hook";
import { GroupManagementSideMenu } from "features/Group";
import { useUserData } from "../hooks";

export default function SideMenu() {
  const isMobile = useIsMobile();
  const { instance } = useMsal();
  const { handleLogout } = useAuth({
    handleLoading: () => {},
  });
  const { showSubscriptions } = useUserData();

  const { user } = useSelector((state) => state.auth);
  const { microsoftUser } = useSelector((state) => state.data);



  return (
    <Stack direction="column" gap={1} sx={{ ml: 3, pt: 2 }}>
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        my={2}
        pr={1}
        maxWidth={isMobile ? "50vw" : "15vw"}
      >
        <ProfileAvatar size="Large" userId={user.userId} />
        <Typography variant="subtitle2" noWrap>
          <Tooltip title={`@${user.userName}`}>
            <React.Fragment>@{user.userName}</React.Fragment>
          </Tooltip>
        </Typography>
      </Stack>
      <SideNavLink to="/profile">Profile</SideNavLink>
      <SideNavLink to="/profile/info-diet">Info Diet</SideNavLink>
      {user.roles.includes("Admin") && (
        <SideNavLink to="/profile/sooth-admin">Admin</SideNavLink>
      )}
      {/* <SideNavLink to="/profile/notifications">Notifications</SideNavLink> */}

      <GroupManagementSideMenu />

      <Divider />
      <Typography variant="h6" color="#5c6d7e" fontStyle="italic" mt={1.3}>
        Settings
      </Typography>
      {/* <SideNavLink to="/profile/account">Account</SideNavLink> */}
      <SideNavLink to="/profile/preferences">Preferences</SideNavLink>
      {showSubscriptions && (
        <SideNavLink end={false} to="/profile/subscription">
          Subscription
        </SideNavLink>
      )}
      <Link
        onClick={() => handleLogout(microsoftUser, instance)}
        className="side-nav-link"
      >
        Logout
      </Link>
    </Stack>
  );
}
