import { Stack } from "@mui/material";
import AllSidesCategoryIcon from "./AllSidesCategoryIcon";

export default function AllSidesRating({ activeCategory, size = "small" }) {
  return (
    <Stack direction="row" gap={0.5}>
      <AllSidesCategoryIcon
        size={size}
        category="L"
        color=" #126ba3"
        active={activeCategory === "Left"}
      />
      <AllSidesCategoryIcon
        size={size}
        category="L"
        color=" #a3becf"
        active={activeCategory === "Lean Left"}
      />
      <AllSidesCategoryIcon
        size={size}
        category="C"
        color=" #6e3aa6"
        active={activeCategory === "Center"}
      />
      <AllSidesCategoryIcon
        size={size}
        category="R"
        color=" #bd6c81"
        active={activeCategory === "Lean Right"}
      />
      <AllSidesCategoryIcon
        size={size}
        category="R"
        color=" #a10830"
        active={activeCategory === "Right"}
      />
    </Stack>
  );
}
