import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Stack,
  MenuItem,
  TextField,
  useTheme,
  Link,
  Button,
  Tooltip,
} from "@mui/material";
import { useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { getFormattedDate } from "utils/time.util";
import {
  setCollectionId,
  setSelectedBookmarkOption,
} from "context/features/dataSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { logEvent } from "services/amplitude.service";

const Header = ({
  toggleSidebar = null,
  selectedBookmarkOption,
  edit,
  setEdit,
  saveNewCollection,
  setSelectedCollection,
  selectedCollection,
  switchBookmark,
  setDeletePopup,
  create,
  setCreate,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [bookmarkTitle, setBookmarkTitle] = useState("");
  const dispatch = useDispatch();

  const theme = useTheme();
  const navbarBackground = theme.palette.secondary.main;

  const {
    summaryInfoData,
    collections,
    collectionSourceId,
    collectionId,
    parentCollections,
  } = useSelector((state) => state.data);

  const handleSelectBookmarkOption = (option) => {
    dispatch(setSelectedBookmarkOption(option));
    logEvent(`click_${option}_option`, { click: true });
  };

  const handleSelect = async (event) => {
    if (event.target.value === "create_new") {
      dispatch(setCollectionId(null));
      setSelectedCollection(null);
    } else if (create) {
      dispatch(setCollectionId(event.target.value));
      setSelectedCollection(event.target.value);
    } else {
      await switchBookmark(event.target.value);
      toast.success("Bookmark saved to collection successfully!");
    }
  };

  const handleSaveNewCollection = async () => {
    if (bookmarkTitle.length === 0) {
      toast.error("Collection name cannot be empty!");
      return;
    }
    const id = await saveNewCollection(bookmarkTitle);
    dispatch(setCollectionId(id));
    setCreate(false);
    await switchBookmark(id);
    toast.success("Bookmark saved to collection successfully!");
  };

  const TopHeader = () => {
    return (
      <Stack
        direction="row"
        p={2}
        justifyContent="space-between"
        alignItems="center"
      >
        {!edit ? (
          <Typography
            variant="h5"
            gutterBottom
            sx={{ color: "#696b6c", fontSize: "2rem", mb: 0 }}
          >
            Details
          </Typography>
        ) : (
          <Typography
            onClick={() => logEvent("click_edit_bookmark", { click: true })}
            variant="h5"
            gutterBottom
            sx={{ color: "#696b6c", fontSize: "2rem", mb: 0 }}
          >
            Edit Bookmark
          </Typography>
        )}

        <Stack direction="row" spacing={0.5}>
          {collectionSourceId && (
            <>
              {!edit && (
                <IconButton onClick={() => setEdit(true)} color="inherit">
                  <EditOutlinedIcon
                    sx={{ color: "#a9baca", height: "2.2rem" }}
                  />
                </IconButton>
              )}
              <IconButton onClick={() => setDeletePopup(true)}>
                <DeleteOutlineIcon
                  sx={{ color: "#a9baca", height: "2.2rem" }}
                />
              </IconButton>
            </>
          )}
          {toggleSidebar != null && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={toggleSidebar}
            >
              <CloseIcon sx={{ color: "#a9baca", height: "2.2rem" }} />
            </IconButton>
          )}
        </Stack>
      </Stack>
    );
  };

  const BookmarkOptions = () => {
    const getBookmarkSxProps = (option) => ({
      fontStyle: "italic",
      cursor: "pointer",
      textDecoration: selectedBookmarkOption === option ? "underline" : "none",
      textDecorationThickness: "2px",
      textUnderlineOffset: "8px",
      color:
        selectedBookmarkOption === option
          ? theme.palette.primary.main
          : "#696b6c",
    });

    return (
      <Stack mt={3} mb="8px" direction="row" justifyContent="center" gap={3}>
        <Typography
          variant="h6"
          sx={getBookmarkSxProps("notes")}
          onClick={() => {
            handleSelectBookmarkOption("notes");
          }}
        >
          Notes
        </Typography>
        <Typography
          variant="h6"
          sx={getBookmarkSxProps("citation")}
          onClick={() => handleSelectBookmarkOption("citation")}
        >
          Citation
        </Typography>
        <Typography
          variant="h6"
          sx={getBookmarkSxProps("summary")}
          onClick={() => handleSelectBookmarkOption("summary")}
        >
          Summary
        </Typography>
      </Stack>
    );
  };

  // if (collections.length === 0) {
  //   return (
  //     <Box
  //       sx={{
  //         backgroundColor: navbarBackground,
  //         height: "40vh",
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "center",
  //       }}
  //     >
  //       <CircularProgress />
  //     </Box>
  //   );
  // }

  useEffect(() => {
    if (collectionId && collectionSourceId && collections.length) {
      setSelectedCollection(collectionId);
    }
  }, [collectionId, collections]);

  const ellipsisStyle = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  return (
    <Box sx={{ backgroundColor: navbarBackground }}>
      <TopHeader />

      {!edit && (
        <React.Fragment>
          <Stack px={5} direction="column" gap={1}>
            <Tooltip title={summaryInfoData.title}>
              <Typography noWrap={true} variant="body4">
                {summaryInfoData.title}
              </Typography>
            </Tooltip>
            <Tooltip title={summaryInfoData.url}>
              <Link
                href={summaryInfoData.url}
                variant="body3"
                color="grey"
                underline="none"
                target="_blank"
                noWrap={true}
              >
                {summaryInfoData.url}
              </Link>
            </Tooltip>
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography
                variant="body2"
                color={theme.palette.grey[700]}
                fontWeight="bold"
              >
                {summaryInfoData.publisher}
              </Typography>
              <Typography variant="body2" color="grey">
                {getFormattedDate(summaryInfoData.published_date)}
              </Typography>
            </Stack>
            {create && (
              <TextField
                label="Enter new Collection name"
                value={bookmarkTitle}
                onChange={(e) => setBookmarkTitle(e.target.value)}
              ></TextField>
            )}
            <TextField
              select
              label={
                !create
                  ? "Add to Collection..."
                  : "Creating a sub-folder? Pick parent Collection here"
              }
              value={selectedCollection}
              onChange={handleSelect}
              variant="outlined"
              SelectProps={{
                open: dropdownOpen,
                onOpen: () => setDropdownOpen(true),
                onClose: () => setDropdownOpen(false),
                onClick: (event) => event.stopPropagation(),
              }}
              sx={{
                mt: 1.5,
              }}
            >
              {!create && (
                <MenuItem
                  key={0}
                  onClick={() => {
                    setBookmarkTitle("");
                    setCreate(true);
                  }}
                  value="create_new"
                >
                  + Create New
                </MenuItem>
              )}
              {create
                ? parentCollections.length &&
                  parentCollections.map((collection) => (
                    <MenuItem
                      key={collection.collectionId}
                      value={collection.collectionId}
                    >
                      {collection.name}
                    </MenuItem>
                  ))
                : collections.length &&
                  collections.map((collection) => (
                    <MenuItem
                      key={collection.collectionId}
                      value={collection.collectionId}
                    >
                      {collection.name}
                    </MenuItem>
                  ))}
            </TextField>
            {create && (
              <Box
                sx={{
                  backgroundColor: navbarBackground,
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  onClick={handleSaveNewCollection}
                  sx={{
                    mt: 2,
                    textTransform: "none",
                    color: "#fff",
                  }}
                >
                  Save
                </Button>
              </Box>
            )}
          </Stack>

          <BookmarkOptions />
        </React.Fragment>
      )}
    </Box>
  );
};

export default Header;
