import { Box, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

export default function NewsguardCriteria({ criteria, value }) {
  const checkIconStyle = {
    color: "green",
    height: 18,
    width: 18,
  };

  const closeStyle = { color: "red", height: 18, width: 18 };

  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      {value === "Yes" ? (
        <CheckIcon sx={checkIconStyle} />
      ) : value === "No" ? (
        <CloseIcon sx={closeStyle} />
      ) : (
        <p className="na">NA</p>
      )}

      <Typography variant="body2">{criteria}</Typography>
    </Box>
  );
}
