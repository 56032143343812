import dayjs from "lib/dayjs";
import { axiosInstance } from "lib/axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { CategoryService } from "features/SourceSearch";
import { GroupService } from "features/Group";
import {
  setAcceptedMISTerms,
  setIsCategoriesSelected,
} from "context/features/dataSlice";
import { loginUser } from "context/features/authSlice";

import {
  setCategoriesData,
  setAccountExpiration,
  setAccountType,
  setAutoRenew,
  setBillingHistory,
  setCurrentPayment,
  setSubscriptionId,
  setGroupId,
  setGradeLicenseLevel,
  setGroupRole,
  setInit,
  setTimeTillExpired,
} from "../context";
import { SubscriptionService, UserInfoService } from "../services";
import { BillingHistoryRow } from "../models";

export function useUserData() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { getCategories } = CategoryService(axiosInstance);
  const userInfoService = new UserInfoService(axiosInstance);
  const subService = new SubscriptionService(axiosInstance);
  const groupService = new GroupService(axiosInstance);

  const { user, isAuthenticated, userLoading } = useSelector(
    (state) => state.auth
  );
  const { categories } = useSelector((state) => state.profile);
  const {
    accountType,
    accountExpiration,
    autoRenew,
    billingHistory,
    currentPayment,
    subscriptionId,
    groupId,
    groupRole,
    gradeLicenseLevel,
    init,
    timeTillExpired,
  } = useSelector((state) => state.account);

  // const [timeTillExpired, setTimeTillExpired] = useState(null);
  const [subscriptionLoading, setSubscriptionLoading] = useState(0);

  const isSubscriptionLoading = useMemo(() => {
    if (user == null) {
      return false;
    }
    return !init || subscriptionLoading > 0;
  }, [subscriptionLoading, init]);

  const showSubscriptions = useMemo(
    () => groupId == null || groupRole === "OrganizationAdmin",
    [groupId, groupRole]
  );

  function handleLoading(isLoading) {
    if (isLoading) {
      setSubscriptionLoading(subscriptionLoading + 1);
    } else {
      setSubscriptionLoading(
        subscriptionLoading > 0 ? subscriptionLoading - 1 : 0
      );
    }
  }

  const checkUserFromStorage = () => {
    if (sessionStorage.getItem("user")) {
      return JSON.parse(sessionStorage.getItem("user"));
    } else {
      return JSON.parse(localStorage.getItem("user"));
    }
  };

  const updateReduxState = (newUser, invalid) => {
    if (invalid) {
      dispatch(loginUser({ user: newUser }));
    } else {
      let userFromStorage = checkUserFromStorage();
      if (
        !user ||
        (userFromStorage && user.userId !== userFromStorage.userId)
      ) {
        dispatch(loginUser({ user: userFromStorage }));
      }
    }
  };

  const calculateTimeTillExpired = useCallback(
    (simulation) => {
      let now = dayjs();
      if (simulation != null) {
        now = dayjs(simulation).isValid() ? dayjs(simulation) : dayjs();
      }
      const expirationDate = dayjs(accountExpiration);
      return expirationDate.diff(now, "day") >= 0
        ? expirationDate.diff(now, "day")
        : 0;
    },
    [accountExpiration]
  );

  const isSubscriptionExpired = useMemo(() => {
    if (searchParams.has("simulation")) {
      const simTimeTillExp = calculateTimeTillExpired(
        searchParams.get("simulation")
      );
      return !isSubscriptionLoading && simTimeTillExp <= 0;
    }
    return (
      !isSubscriptionLoading && timeTillExpired != null && timeTillExpired <= 0
    );
  }, [isSubscriptionLoading, timeTillExpired]);

  async function setCategories(userId) {
    try {
      const data = await getCategories(userId);

      dispatch(setCategoriesData(data));
    } catch (e) {
      console.error(e);
    }
  }

  async function getSubscriptionInfo() {
    if (!isAuthenticated) {
      setInit(true);
      return;
    }
    handleLoading(true);
    try {
      const accountData = await subService.getIndividualSubscriptionData();

      dispatch(setAccountType(accountData.planType));
      dispatch(setAccountExpiration(accountData.expirationDate));
      dispatch(setAutoRenew(accountData.recurring));

      dispatch(
        setCurrentPayment(
          accountData?.paymentMethod == null
            ? null
            : `${accountData?.paymentMethod?.toUpperCase()}${
                accountData.cardLast4 != null
                  ? ` *${accountData.cardLast4.toUpperCase()}`
                  : ""
              }`
        )
      );
      dispatch(setSubscriptionId(accountData.subscriptionId));
      const history = Array.isArray(accountData.billingHistory)
        ? accountData.billingHistory.map((item) => new BillingHistoryRow(item))
        : [];
      dispatch(setBillingHistory(history));
      if (!init) {
        dispatch(setInit(true));
      }
    } catch (error) {
      console.error(error);
    } finally {
      handleLoading(false);
    }
  }

  async function getUserInfo() {
    handleLoading(true);
    try {
      const user = await userInfoService.getUserInfo();
      dispatch(setAcceptedMISTerms(user.acceptedMisTerms));
      dispatch(setIsCategoriesSelected(user.isCategoriesSelected));
      dispatch(setGroupId(user.groupId));
      dispatch(setGradeLicenseLevel(user.gradeLicenseLevel));
      dispatch(setGroupRole(user.groupRole));
    } catch (error) {
      console.error(error);
    } finally {
      handleLoading(false);
    }
  }

  async function updateGroupLicense(groupLicenseKey) {
    try {
      await userInfoService.updateGradeLicenseKey(groupLicenseKey, user.userId);
      dispatch(setGradeLicenseLevel(groupLicenseKey));
    } catch (error) {
      console.error(error);
    }
  }

  async function joinGroup(code) {
    try {
      const response = await groupService.joinGroupCode(code);
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    dispatch(setTimeTillExpired(calculateTimeTillExpired()));
    const interval = setInterval(() => {
      const calcedTime = calculateTimeTillExpired();
      if (calcedTime !== timeTillExpired) {
        dispatch(setTimeTillExpired(calcedTime));
      }
    }, 15 * 60 * 1000);
    return () => clearInterval(interval);
  }, [calculateTimeTillExpired, timeTillExpired, dispatch]);

  return {
    accountExpiration,
    accountType,
    autoRenew,
    billingHistory,
    categories,
    checkUserFromStorage,
    currentPayment,
    getSubscriptionInfo,
    getUserInfo,
    gradeLicenseLevel,
    groupId,
    groupRole,
    isAuthenticated,
    isSubscriptionExpired,
    isSubscriptionLoading,
    joinGroup,
    setCategories,
    showSubscriptions,
    subscriptionId,
    timeTillExpired,
    updateGroupLicense,
    updateReduxState,
    userLoading,
  };
}
