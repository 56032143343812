import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { axiosInstance } from "lib/axios";
import { toastError } from "utils/toast.util";

import { setGroupControlsLoading, setGroupCodes } from "../context";
import { GroupService } from "../services";

const apiSync = { getCodes: false };

export function useGroupControls() {
  const dispatch = useDispatch();

  const groupService = new GroupService(axiosInstance);

  const { groupControlsLoading, groupCodes, group } = useSelector(
    (state) => state.group
  );

  const isGroupControlsLoading = useMemo(
    () => groupControlsLoading > 0,
    [groupControlsLoading]
  );

  function handleLoading(isLoading) {
    if (isLoading) {
      dispatch(setGroupControlsLoading(groupControlsLoading + 1));
    } else {
      dispatch(
        setGroupControlsLoading(
          groupControlsLoading > 0 ? groupControlsLoading - 1 : 0
        )
      );
    }
  }

  async function getGroupCodes() {
    if (apiSync.getCodes) {
      return;
    }
    apiSync.getCodes = true;
    handleLoading(true);
    try {
      const codes = await groupService.getGroupCodes();
      dispatch(setGroupCodes(codes));
    } catch (error) {
      toastError("Could not retrieve Group Codes.");
    } finally {
      handleLoading(false);
      apiSync.getCodes = false;
    }
  }

  async function createGroupCode() {
    handleLoading(true);
    try {
      const newCode = await groupService.createGroupCode();
      dispatch(setGroupCodes([...groupCodes, newCode]));
      return newCode;
    } catch (error) {
      toastError("Unable to create new Group Code.");
    } finally {
      handleLoading(false);
    }
  }

  async function deleteGroupCode(groupCode) {
    handleLoading(true);
    try {
      await groupService.deleteGroupCode(groupCode.id);
    } catch (error) {
      toastError("Could not delete Group Code: " + groupCode.code);
    } finally {
      handleLoading(false);
    }
  }

  useEffect(() => {
    async function fetchData() {
      await getGroupCodes();
    }
    fetchData();
  }, [group]);

  return {
    createGroupCode,
    deleteGroupCode,
    getGroupCodes,
    groupCodes,
    isGroupControlsLoading,
  };
}
