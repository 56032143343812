import React, { useState } from "react";
import {
  Button,
  IconButton,
  Box,
  TextField,
  Typography,
  MenuItem,
  useTheme,
  Modal,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const NewSubCollectionPopup = ({ subPopup, setSubPopup, saveCollection }) => {
  const { parentCollections } = useSelector((state) => state.data);
  const [title, setTitle] = useState("");
  const [parentCollection, setParentCollection] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const navbarBackground = theme.palette?.navbar?.main;

  const params = useParams();

  const handleSelect = (event) => {
    setParentCollection(event.target.value);
  };

  const handleSave = async () => {
    if (title.length === 0) {
      toast.error("Empty Collection name not allowed.");
      return;
    }
    const id = await saveCollection(title, true, params.id);
    navigate(`/collections/${id}`);
    setSubPopup(false);
  };

  const onClose = () => {
    setSubPopup(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSave();
    }
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "100vw", sm: "50vw", md: "34vw" },
    bgcolor: "background.paper",
    boxShadow: 0,
    borderRadius: 2,
  };

  return (
    <Modal open={subPopup} onClose={onClose}>
      <Box sx={modalStyle}>
        <Box sx={{ p: 5 }}>
          <Typography
            variant="h4"
            component="div"
            color="#406da4"
            fontWeight="600"
          >
            Create a new Sub Collection
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <Box mt={2}>
            {/* <TextField
              select
              label="Select a parent Folder"
              value={parentCollection}
              onChange={handleSelect}
              fullWidth
              variant="outlined"
              SelectProps={{
                MenuProps: { disableScrollLock: true },
                open: dropdownOpen,
                onOpen: () => setDropdownOpen(true),
                onClose: () => setDropdownOpen(false),
              }}
              sx={{ mb: 2 }}
            >
              <MenuItem key="new" value={null} disabled={true}>
                Select a parent Folder
              </MenuItem>
              {parentCollections.map((collection) => (
                <MenuItem
                  key={collection.collectionId}
                  value={collection.collectionId}
                >
                  {collection.name}
                </MenuItem>
              ))}
            </TextField> */}
            <TextField
              sx={{ width: "100%" }}
              value={title}
              placeholder="Enter new Collection name"
              onChange={(e) => setTitle(e.target.value)}
              onKeyDown={handleKeyPress}
            />
            <Typography mt={2} variant="body2" fontStyle="italic">
              Please note: Sharing Collections with others happens at the
              parent-Collection level. All subfolders within a Collection get
              automatically shared whenever the parent Collection is shared.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: navbarBackground,
            display: "flex",
            justifyContent: "space-between",
            px: 5,
            py: 3,
            borderRadius: "0 0 20px 20px",
          }}
        >
          <Button onClick={onClose} variant="outlined" sx={{ width: "45%" }}>
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            sx={{
              width: "45%",
            }}
          >
            Create
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default NewSubCollectionPopup;
