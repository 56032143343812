import { axiosInstance } from "lib/axios";
import { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  setGroup,
  setUsers,
  setGroupLoading,
  setPaginationModel as setPaginationModelContext,
  setFilterModel as setFilterModelContext,
  setSortModel as setSortModelContext,
  setTotalSize as setTotalSizeContext,
  DEFAULT_PAGING,
} from "../context";
import { GroupAdminService, GroupService } from "../services";
import { useUserData } from "features/User";
import { toastError } from "utils/toast.util";

export function useGroup() {
  const dispatch = useDispatch();
  const { groupId } = useUserData();
  const groupService = useMemo(() => new GroupService(axiosInstance), []);
  const groupAdminService = new GroupAdminService(axiosInstance);

  const {
    filterModel,
    group,
    groupLoading,
    paginationModel,
    roles,
    sortModel,
    totalSize,
    users,
  } = useSelector((state) => state.group);

  // const [totalSize, setTotalSize] = useState(0);

  const isGroupLoading = useMemo(() => groupLoading > 0, [groupLoading]);

  function handleLoading(isLoading) {
    if (isLoading) {
      dispatch(setGroupLoading(groupLoading + 1));
    } else {
      dispatch(setGroupLoading(groupLoading > 0 ? groupLoading - 1 : 0));
    }
  }

  function setFilterModel(value) {
    dispatch(setFilterModelContext(value));
  }

  function setSortModel(value) {
    dispatch(setSortModelContext(value));
  }

  function setPaginationModel(value) {
    dispatch(setPaginationModelContext(value));
  }

  function setTotalSize(value) {
    dispatch(setTotalSizeContext(value));
  }

  function resetPage() {
    return {
      page: DEFAULT_PAGING.page,
      pageSize: paginationModel.pageSize,
    };
  }

  async function resetUsers() {
    setPaginationModel(resetPage());
    await getGroupUsers(
      paginationModel.page,
      paginationModel.pageSize,
      filterModel.items[0],
      sortModel[0]
    );
  }

  async function getGroup(groupId) {
    handleLoading(true);
    try {
      const group = await groupService.getGroup(groupId);
      dispatch(setGroup(group));
    } catch (error) {
      console.error(error);
    } finally {
      handleLoading(false);
    }
  }

  async function getGroupUsers(page, pageSize, filter, sort) {
    try {
      const usersData = await groupService.getUsers(
        page,
        pageSize,
        filter,
        sort
      );
      dispatch(setUsers(usersData.users));
      setTotalSize(usersData.total);
    } catch (error) {
      dispatch(setUsers([]));
      setTotalSize(0);
      setPaginationModel(DEFAULT_PAGING);
    } finally {
      handleLoading(false);
    }
  }

  async function addUser(email, licenseKey, role) {
    try {
      const newUser = await groupAdminService.addUserToGroup(
        email,
        licenseKey,
        role
      );
      await getGroup(groupId);
      getGroupUsers(
        paginationModel.page,
        paginationModel.pageSize,
        filterModel.items[0],
        sortModel[0]
      );
      return { newUser };
    } catch (error) {
      toastError(`Could not add user with email, '${email}'`);
      return { newUser: null, error };
    }
  }

  async function removeUsersFromGroup(users) {
    const response = await groupAdminService.removeUserFromGroup(users);
    if (response.success) {
      setPaginationModel(resetPage());
      await getGroup(groupId);
      getGroupUsers(
        DEFAULT_PAGING.page,
        paginationModel.pageSize,
        filterModel.items[0],
        sortModel[0]
      );
      return true;
    } else {
      toastError("Could not delete users from group");
      return false;
    }
  }

  async function updateUsersRole(users, role, forceRefresh = false) {
    const response = await groupAdminService.updateUsersRole(users, role);
    if (response.success && forceRefresh) {
      getGroupUsers(
        paginationModel.page,
        paginationModel.pageSize,
        filterModel.items[0],
        sortModel[0]
      );
    } else if (!response.success) {
      toastError("Could not update group role");
    }
    return response.success;
  }

  async function updateUsersLicenseLevel(
    users,
    licenseKey,
    forceRefresh = false
  ) {
    const response = await groupAdminService.updateUsersLicenseLevel(
      users,
      licenseKey
    );
    if (response.success && forceRefresh) {
      getGroupUsers(
        paginationModel.page,
        paginationModel.pageSize,
        filterModel.items[0],
        sortModel[0]
      );
    } else if (!response.success) {
      toastError("Could not update license level");
    }
    await getGroup(groupId);
    return response.success;
  }

  async function uploadBulkUsers(file) {
    const response = await groupAdminService.bulkUploadUserCSV(file);
    if (!response.success) {
      toastError("Could not upload bulk users");
    }
    if (response.errors == null) {
      await getGroup(groupId);
    }
    return response;
  }

  useEffect(() => {
    async function fetchData() {
      await getGroup(groupId);
    }
    fetchData();
  }, [groupId]);

  useEffect(() => {
    handleLoading(true);
    async function fetchData() {
      await getGroupUsers(
        paginationModel.page,
        paginationModel.pageSize,
        filterModel.items[0],
        sortModel[0]
      );
    }
    fetchData();
  }, [
    groupId,
    paginationModel,
    filterModel,
    sortModel,
    dispatch,
    groupService,
  ]);

  return {
    addUser,
    group,
    groupRoles: roles,
    removeUsersFromGroup,
    isGroupLoading,
    paginationModel,
    resetUsers,
    setFilterModel,
    setPaginationModel,
    setSortModel,
    sortModel,
    totalSize,
    updateUsersLicenseLevel,
    updateUsersRole,
    uploadBulkUsers,
    users,
  };
}
