import React, { useEffect, useState, useRef } from "react";
import { Box, Grid, Divider, Stack, TextField } from "@mui/material";
import PageTitle from "components/layout/PageTitle";
import WhatsYourPulse from "./WhatsYourPulse";
import { useGetFeedSearch } from "../hooks/get-feed-search.hook";
import Markdown from "features/CategoryNews/component/MarkdownNote";
import useLastElementRef from "../hooks/use-last-element-ref.hook";
import NewsCard from "./news-card";
import ItemSkeleton from "components/base/ItemSkeleton";
import { useIsTab } from "hooks/is-tab.hook";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setCollectionId,
  setCollectionSourceId,
  setIsCitationAvailable,
  setIsSidebarOpen,
  setIsSummaryAvailable,
  setPulseCategoryId,
  setSelectedBookmarkOption,
  setSummaryInfoData,
} from "context/features/dataSlice";
import { useGetFeedCategories } from "../hooks/get-feed.hook";
import { useSelector } from "react-redux";

const YourPulse = () => {
  const { isCategoriesSelected } = useSelector((state) => state.data);
  const [loading, setLoading] = useState(true);
  const { feedCategories } = useGetFeedCategories();
  const scrollRef = useRef(null);

  const { feedResult, setOffset, hasMore } = useGetFeedSearch(
    setLoading,
    scrollRef
  );
  const { lastElementRef } = useLastElementRef(loading, hasMore, setOffset);

  const isTab = useIsTab();
  const { pathname } = useLocation();
  const { category } = useParams();
  const dispatch = useDispatch();

  const menuClick = (item) => {
    dispatch(setSelectedBookmarkOption("notes"));

    dispatch(setCollectionSourceId(item.collectionSourceId));
    dispatch(
      setSummaryInfoData({
        publisher: item.entityName,
        title: item.name,
        published_date: item.datePublishedDisplayText,
        url: item.url,
      })
    );
    dispatch(setCollectionId(item.collectionId));
    dispatch(setIsCitationAvailable(item.isCitationAvailable));
    dispatch(setIsSummaryAvailable(item.isSummaryAvailable));
    dispatch(setIsSidebarOpen(true));
  };

  useEffect(() => {
    if (feedCategories.length > 0) {
      if (!category) {
        dispatch(setPulseCategoryId(null));
      } else {
        const pulseCatId = feedCategories.filter(
          (feed) => feed.categoryName === category
        )[0]?.categoryId;
        dispatch(setPulseCategoryId(pulseCatId));
      }
    }
  }, [pathname, feedCategories]);

  return (
    <Box>
      {!isCategoriesSelected && !category ? (
        <WhatsYourPulse feedCategories={feedCategories} />
      ) : (
        <Box sx={{ width: "100%" }}>
          <Box ref={scrollRef} />
          <PageTitle>{category ? category : "Your Pulse"}</PageTitle>
          <Grid container spacing={2} sx={{ pt: 4 }}>
            {feedResult &&
              feedResult.map((row, index) => (
                <Box
                  key={row.url}
                  sx={{
                    display: "grid",
                    px: { xs: 0, sm: 1 },
                    mb: { xs: 2, sm: "auto" },
                    width: "100%",
                    gridTemplateAreas: !isTab
                      ? '"bookmark divide notes"'
                      : '"bookmark"',
                    gridTemplateColumns: !isTab ? "70% auto 26%" : "1fr",
                    "&:hover .enterNoteField": { opacity: 1 },
                  }}
                >
                  <Box sx={{ gridArea: "bookmark" }}>
                    <NewsCard
                      cardRef={
                        feedResult?.length === index + 1
                          ? lastElementRef
                          : undefined
                      }
                      item={row}
                      index={index}
                    />
                  </Box>
                  {!isTab && (
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ gridArea: "divide", justifySelf: "center" }}
                    />
                  )}
                  {!isTab && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gridArea: "notes",
                      }}
                    >
                      {row.notes != null && row.notes.length > 0 ? (
                        <Markdown
                          item={row}
                          note={row.notes[0]}
                          onClick={menuClick}
                        />
                      ) : (
                        <TextField
                          className="enterNoteField"
                          sx={{
                            width: "100%",
                            my: "auto",
                            opacity: 0,
                            transition: "all .4s ease",
                          }}
                          onClick={() => menuClick(row)}
                          label="Enter Note"
                          variant="outlined"
                        />
                      )}
                    </Box>
                  )}
                </Box>
              ))}
          </Grid>
          {loading && (
            <Stack
              direction="column"
              sx={{ maxWidth: isTab ? "100%" : "70%", pl: 3, pt: 2 }}
            >
              {Array.from(Array(7), (e, i) => (
                <React.Fragment key={`skeleton-${i}`}>
                  <Box>
                    <ItemSkeleton
                      sx={{ minHeight: "200px" }}
                      key={`dummy-${i}`}
                    />
                  </Box>
                  <Divider />
                </React.Fragment>
              ))}
            </Stack>
          )}
        </Box>
      )}
    </Box>
  );
};

export default YourPulse;
