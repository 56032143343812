import React from "react";
import { Box } from "@mui/material";
import { fillParent, scrollingBox, footerFlex } from "utils/base-styles";
import Summarizer from "features/Summarizer";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setIsBookmarkSidebar } from "context/features/dataSlice";
import ExternalBookmarkDrawer from "features/ExternalBookmark";
import Footer from "components/layout/Footer";

export default function SummarizerPage() {
  const { isBookmarkSidebar } = useSelector((state) => state.data);

  const dispatch = useDispatch();

  const externalBookmarkCloseHandler = () => {
    dispatch(setIsBookmarkSidebar(false));
  };

  const showExternalBookmarkSidebar = () => {
    dispatch(isBookmarkSidebar(true));

    if (typeof window != "undefined" && window.document) {
      document.body.style.overflow = "hidden";
    }
  };

  return (
    <Box sx={{ ...fillParent, ...scrollingBox, ...footerFlex }}>
      <Summarizer />
      <Footer />
      <ExternalBookmarkDrawer
        isBookmarkSidebar={isBookmarkSidebar}
        externalBookmarkCloseHandler={externalBookmarkCloseHandler}
        showExternalBookmarkSidebar={showExternalBookmarkSidebar}
      />
    </Box>
  );
}
