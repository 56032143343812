import { Fragment } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { AddOutlined } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Divider,
  List,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import Footer from "components/layout/Footer";
import PageTitle from "components/layout/PageTitle";
import { fillParent, scrollingBox, footerFlex } from "utils/base-styles";

import {
  useGroup,
  AutoJoinDomain,
  useGroupControls,
  GroupCodeListItem,
} from "features/Group";

export default function GroupControlsPage() {
  const { palette } = useTheme();

  const { groupId, isGroupLoading } = useGroup();
  const { groupCodes, createGroupCode, isGroupControlsLoading } =
    useGroupControls();

  async function handleCreateGroupCode() {
    const newCode = await createGroupCode();
    toast.success(`Created new Group Code: '${newCode.code}'`);
  }

  return (
    <Box
      sx={{
        ...fillParent,
        ...scrollingBox,
        ...footerFlex,
      }}
    >
      <Stack direction="column">
        <PageTitle>Group Controls</PageTitle>
        <Typography variant="body2">
          Group controls are where you can setup alternative methods for users
          to join your group account and also to control access to different
          parts of Sooth's research tools for your users.
        </Typography>
        <Divider />
        <Typography id="group-codes" variant="h4">
          Group Codes
        </Typography>
        <Typography>
          Group codes are an easy way to let users join your group. You can
          generate a new code and give it out to the individuals you want to
          join your group.
        </Typography>
        <Typography>
          This method of adding users to your group offers flexibility, allowing
          you to control access by individual classrooms or manage user cycles
          from one semester to the next, putting you in the driver's seat.
        </Typography>
        <Alert severity="info">
          All users will be added to the "Student" role when they join. You can
          adjust this later in <Link to="user-management">User Management</Link>
        </Alert>
        <Alert severity="info">
          Deleting a group code will not delete users who have joined your group
          with that code. It will, however, prevent anyone using that code from
          joining your group in the future.
        </Alert>
        {!isGroupLoading && (
          <Fragment>
            <Box>
              <Button
                variant="outlined"
                startIcon={<AddOutlined fontSize="inherit" />}
                onClick={handleCreateGroupCode}
                disabled={isGroupControlsLoading}
              >
                Create Group Code
              </Button>
            </Box>
            <List
              sx={{
                pr: 50,
                "> li:not(:last-child)": {
                  borderBottom: `1px solid ${palette.divider}`,
                },
              }}
            >
              {groupCodes.map((groupCode) => (
                <GroupCodeListItem
                  groupCode={groupCode}
                  key={`group-code-${groupId}-${groupCode.id}`}
                />
              ))}
            </List>
          </Fragment>
        )}
        <Divider />
        <Typography id="auto-join-domains" variant="h4">
          Auto-join Domains (Coming Soon)
        </Typography>
        <Typography>
          Auto-join domains allow you to specify one or more domains that
          automatically join users to your group when they log in.
        </Typography>
        <Alert severity="warning">
          This method of adding users to your domain is ideal when you want
          anyone with a specific email domain to be automatically added to the
          group. Ensure your group account has enough seats for everyone with
          that domain.
        </Alert>
        <Alert severity="info">
          All users will be added to the "Student" role when they join. You can
          adjust this later in <Link to="user-management">User Management</Link>
        </Alert>
        {/* <Box>
              <Button
                variant="outlined"
                startIcon={<AddOutlined fontSize="inherit" />}
              >
                Add Auto-join Domain
              </Button>
            </Box>
            <List
              sx={{
                pr: 50,
                "> li:not(:last-child)": {
                  borderBottom: `1px solid ${palette.divider}`,
                },
              }}
            >
              <AutoJoinDomain
                groupDomain={{
                  id: 0,
                  domain: "usd260.com",
                  verified: true,
                }}
              />
              <AutoJoinDomain
                groupDomain={{
                  id: 1,
                  domain: "derbyschools.com",
                  verified: false,
                }}
              />
            </List> */}
      </Stack>
      <Footer />
    </Box>
  );
}
