import React, { useEffect, useState } from "react";
import { Box, Skeleton, Typography } from "@mui/material";
import useSummarize from "./hooks/use-summarize.hook";
import InitialSummary from "./components/InitialSummary";
import LoaderPage from "components/layout/LoaderPage";
import GeneratedSummary from "./components/GeneratedSummary";
import { convertFromHTML, ContentState, EditorState } from "draft-js";

import { marked } from "marked";
import PageTitle from "components/layout/PageTitle";

export default function Summarizer() {
  const [rawText, setRawText] = useState("");
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);

  const [markdownSummary, setmarkdownSummary] = useState("");
  const [htmlSummary, sethtmlSummary] = useState(
    EditorState.createEmpty(markdownToContentState(markdownSummary))
  );

  function markdownToHtml(markdown) {
    return marked(markdown);
  }

  function markdownToContentState(markdown) {
    const html = markdownToHtml(markdown);
    const contentState = ContentState.createFromBlockArray(
      convertFromHTML(html).contentBlocks
    );
    return EditorState.createWithContent(contentState);
  }

  const { handleGenerateSummary, handleCopy, handleMouseOut, changeTooltip } =
    useSummarize(
      setLoading,
      rawText,
      setRawText,
      setmarkdownSummary,
      markdownSummary
    );

  useEffect(() => {
    sethtmlSummary(markdownToContentState(markdownSummary));
  }, [markdownSummary, edit]);

  return (
    <Box sx={{ width: { sm: "100%", md: "60%" } }}>
      <PageTitle>Summarizer</PageTitle>

      {loading ? (
        <React.Fragment>
          <Skeleton
            sx={{ mt: 2 }}
            variant="rectangular"
            width="100%"
            height="25vh"
          />
          <Skeleton
            sx={{ mt: 2 }}
            variant="rectangular"
            width="100%"
            height="5vh"
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          {markdownSummary ? (
            <GeneratedSummary
              markdownSummary={markdownSummary}
              handleGenerateSummary={handleGenerateSummary}
              setmarkdownSummary={setmarkdownSummary}
              handleCopy={handleCopy}
              handleMouseOut={handleMouseOut}
              changeTooltip={changeTooltip}
              htmlSummary={htmlSummary}
              sethtmlSummary={sethtmlSummary}
              handleSave={() => {}}
            />
          ) : (
            <InitialSummary
              rawText={rawText}
              setRawText={setRawText}
              handleGenerateSummary={handleGenerateSummary}
            />
          )}
        </React.Fragment>
      )}
    </Box>
  );
}
