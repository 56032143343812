import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { getFeedSearch } from "../services/news-feed.services";
import { uniqBy } from "lodash";
import { NewsItem } from "../models";
import { useParams } from "react-router-dom";

export const useGetFeedSearch = (setLoading, scrollRef) => {
  const { user } = useSelector((state) => state.auth);
  const pageRef = useRef(0);

  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [feedResult, setFeedResult] = useState([]);

  const { pulseCategoryId, bookmarkedData } = useSelector(
    (state) => state.data
  );

  const { category } = useParams();

  const fetchFeedSearch = async () => {
    try {
      setLoading(true);
      const results = await getFeedSearch(
        user.userId,
        pageRef.current,
        21,
        pulseCategoryId
      );

      const mapppedResults = results.map((result, index) =>
        new NewsItem().initialize(result, index)
      );

      setTimeout(
        () =>
          setFeedResult((previousData) => {
            const catData = uniqBy([...previousData, ...mapppedResults], "url");
            return catData;
          }),
        0
      );
      setHasMore(!!results.length);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch feed categories:", error);
    }
  };

  useEffect(() => {
    if (pulseCategoryId) {
      setOffset(0);
      setFeedResult([]);
      pageRef.current = 0;
      fetchFeedSearch();
      pageRef.current = 1;
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [pulseCategoryId]);

  useEffect(() => {
    if (!pulseCategoryId && !category) {
      setFeedResult([]);
      pageRef.current = 0;
      fetchFeedSearch();
      pageRef.current = 1;
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [pulseCategoryId]);

  useEffect(() => {
    if (pageRef.current) {
      if (offset !== 0) {
        fetchFeedSearch();
        pageRef.current += 1;
      }
    }
  }, [offset]);

  useEffect(() => {
    if (bookmarkedData.id && feedResult?.length) {
      const bookmarkedNewsData = feedResult.map((item) => {
        if (item.url === bookmarkedData.id) {
          const itemData = {
            ...item,
            collectionSourceId: bookmarkedData.collectionSourceId,
            collectionId: bookmarkedData.collectionId,
            isCitationAvailable: bookmarkedData.isCitationAvailable,
            isSummaryAvailable: bookmarkedData.isSummaryAvailable,
          };
          return itemData;
        }
        return item;
      });
      setFeedResult(bookmarkedNewsData);
    }
  }, [
    bookmarkedData.collectionId,
    bookmarkedData.collectionSourceId,
    bookmarkedData.id,
  ]);

  return { feedResult, setOffset, setHasMore, hasMore };
};
