export const roles = [
  {
    key: "User",
    label: "Standard User",
  },
  {
    key: "Admin",
    label: "Super Admin",
  },
];
