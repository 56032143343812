import { Fragment, useEffect } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Chip,
  Link,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";

import {
  useUserData,
  SubscriptionTrialOptions,
  IndividualSubscriptionDetails,
} from "features/User";
import { fillParent, footerFlex, scrollingBox } from "utils/base-styles";

import PageTitle from "components/layout/PageTitle";
import Footer from "components/layout/Footer";

export default function SubscriptionPage() {
  const { accountType, timeTillExpired, getSubscriptionInfo } = useUserData();

  useEffect(() => {
    getSubscriptionInfo();
  }, []);

  return (
    <Box
      sx={{
        ...fillParent,
        ...scrollingBox,
        ...footerFlex,
      }}
    >
      {accountType === "trial" ? (
        <Fragment>
          <Stack direction="column">
            <PageTitle>
              Subscription Management{" "}
              <Chip
                color={timeTillExpired > 30 ? "success" : "default"}
                label={`${timeTillExpired} days left in ${
                  timeTillExpired > 30 ? "extended trial" : "trial"
                }`}
              />
            </PageTitle>
            {timeTillExpired > 30 && (
              <Alert severity="success">
                <AlertTitle>Extended Trial</AlertTitle>
                You created your account during our public beta or free early
                adoption periods and are on an extended trial. To ensure you
                receive the full trial time, we have turned off the subscription
                plan options until you are within 30 days of your trial end. If
                you want to sign up for a subscription before then, please get
                in touch with us at{" "}
                <Link href="mailto:contact@sooth.fyi">contact@sooth.fyi</Link>,
                and we can shorten your trial period to 30 days to allow you to
                set up your subscription. Otherwise, please enjoy your extended
                trial, and we look forward to your continued patronage.
              </Alert>
            )}
            <Typography>
              Welcome to the subscriptions management page. You are currently
              accessing Sooth's research platform on a trial account. Please
              select from the subscription plans below to access our range of
              tools after your trial expires. We have two levels of pricing:
              K-12 and Higher Education.
            </Typography>
            <List>
              <ListItem>
                To qualify for K-12, you must provide a valid K-12 school email
                address.{" "}
              </ListItem>
              <ListItem>
                For Higher Education, you must provide a valid school email
                address (sorry, no alumni emails!)
              </ListItem>
              <ListItem>
                Pricing is the same for students and educators within each
                level.
              </ListItem>
            </List>
            <SubscriptionTrialOptions />
          </Stack>
          <Footer />
        </Fragment>
      ) : (
        <Box
          sx={{
            display: "grid",
            gridTemplateAreas: '"title" "current" "group" "history" "cancel" "footer"',
            gridTemplateRows: "auto auto auto auto 1fr auto",
            minHeight: "100%",
          }}
        >
          <PageTitle
            sx={{
              gridArea: "title",
              pb: 3,
            }}
          >
            Subscription Management
          </PageTitle>
          <IndividualSubscriptionDetails />
          <Footer
            sx={{
              gridArea: "footer",
            }}
          />
        </Box>
      )}
    </Box>
  );
}
