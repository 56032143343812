import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import { Link, NavLink, useParams } from "react-router-dom";
import LanguageIcon from "@mui/icons-material/Language";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import { Tooltip, Typography, useTheme } from "@mui/material";

function createNestedStructure(data) {
  let nodeMap = {};
  let root = [];

  data.forEach((item) => {
    nodeMap[item.collectionId] = {
      id: String(item.collectionId),
      name: item.name,
      isShared_ysn: item.isShared_ysn,
      isPublic_ysn: item.isPublic_ysn,
      children: [],
      level: 0,
    };
  });

  data.forEach((item) => {
    const node = nodeMap[item.collectionId];
    if (item.parentCollectionId === 0) {
      root.push(node);
    } else {
      const parentNode = nodeMap[item.parentCollectionId];
      parentNode?.children.push(node);
      if (node?.level) node.level = parentNode.level + 1;
    }
  });

  return root;
}

export default function Treeview({
  collections,
  collectionIdArray,
  setCurrentLevel,
}) {
  const params = useParams();
  const theme = useTheme();

  const treeData = createNestedStructure(collections);
  const [expandedItems, setExpandedItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  function findNodeLevel(nodes, id) {
    for (let node of nodes) {
      if (node.id === id) {
        return node.level;
      }
      if (node.children.length > 0) {
        const childLevel = findNodeLevel(node.children, id);
        if (childLevel !== null) {
          return childLevel;
        }
      }
    }
    return null;
  }

  function TreeNodeContent({ node }) {
    return (
      <NavLink
        to={`/collections/${node.id}`}
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          maxWidth: "200px",
          textDecoration: "none",
          color: "inherit",
        }}
      >
        <Tooltip placement="right" title={node.name}>
          <Typography component="span" noWrap>
            {node.name}
          </Typography>
        </Tooltip>
        {node.isShared_ysn && (
          <PeopleOutlineIcon sx={{ width: "1.8rem", height: "1.8rem" }} />
        )}
        {node.isPublic_ysn && (
          <LanguageIcon sx={{ width: "1.8rem", height: "1.8rem" }} />
        )}
      </NavLink>
    );
  }

  function renderTree(nodes) {
    return nodes.map((node) => (
      <TreeItem
        key={node.id}
        itemId={node.id}
        label={<TreeNodeContent node={node} />}
        sx={{
          "& > .MuiTreeItem-content": {
            bgcolor: "transparent",
            mb: 1,
            height: "3.5rem",
            borderRadius: 0,
            borderTopLeftRadius: "1.75rem",
            borderBottomLeftRadius: "1.75rem",
            "&:hover, &:has(a.active)": {
              bgcolor: theme.palette.secondary.dark,
              color: theme.palette.primary.main,
            },
            "&:has(a.active)": {
              borderRight: `0.3rem solid ${theme.palette.primary.main}`,
            },
          },
        }}
      >
        {node.children.length > 0 ? renderTree(node.children) : null}
      </TreeItem>
    ));
  }

  useEffect(() => {
    setSelectedItem(params.id);
    const newItems = collectionIdArray.filter(
      (id) => !expandedItems.includes(id)
    );
    setExpandedItems((prev) => [...prev, ...newItems]);

    if (params.id) {
      const level = findNodeLevel(treeData, params.id);
      setCurrentLevel(level);
    }
  }, [collectionIdArray, params.id]);

  return (
    <Box sx={{ maxHeight: "50vh", overflowY: "auto" }}>
      <SimpleTreeView
        expandedItems={expandedItems}
        selectedItems={selectedItem}
        onExpandedItemsChange={(event, newExpandedItems) =>
          setExpandedItems(newExpandedItems)
        }
        onSelectedItemsChange={(event, newSelectedItems) =>
          setSelectedItem(newSelectedItems[0])
        }
        aria-label="customized"
      >
        {renderTree(treeData)}
      </SimpleTreeView>
    </Box>
  );
}
