import React from "react";
import { Box, useTheme, Button } from "@mui/material";
import { logEvent } from "services/amplitude.service";
import { useSelector } from "react-redux";

const Submit = ({
  edit,
  setEdit,
  addOrUpdateBookmark,
  articleData,
  citation,
  markdownSummary,
  citationLoaded,
  disable,
}) => {
  const theme = useTheme();
  const navbarBackground = theme.palette?.navbar?.main;

  const { summaryInfoData } = useSelector((state) => state.data);

  const handleSubmit = async () => {
    await addOrUpdateBookmark(
      articleData,
      citation,
      markdownSummary,
      citationLoaded
    );
    logEvent("click_bookmark_save", { click: true, url: summaryInfoData.url });

    setEdit(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: navbarBackground,
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: 5,
        p: 2,
      }}
    >
      {edit && (
        <Button
          onClick={() => setEdit(false)}
          variant="outlined"
          sx={{
            display: "flex",
          }}
        >
          Cancel
        </Button>
      )}
      <Button
        onClick={handleSubmit}
        disabled={disable}
        sx={{
          display: "flex",
          gridColumn: !edit ? 2 : "unset",
        }}
      >
        Save
      </Button>
    </Box>
  );
};

export default Submit;
