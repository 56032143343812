import ToolkitSearchBar from "features/searchbar/toolkitsearchbar";
import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Alert,
  Skeleton,
  Button,
  AlertTitle,
  Link,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import useMisinformationSearch from "../hooks/use-misinformation-search.hook";
import TableRow from "./TableRow";
import SourceSidebar from "features/SourceInfo";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  setAcceptedMISTerms,
  setIsSourceSidebarOpen,
} from "context/features/dataSlice";
import { axiosInstance } from "lib/axios";
import { authAPI } from "services/apis.service";
import Axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { fetchNewsData } from "features/News/services/news.service";

export default function MisinformationSearch() {
  const [loading, setLoading] = useState(false);
  const [getDataLoading, setGetUserDataLoading] = useState(false);
  const [newsCards, setNewsCards] = useState([]);
  const { misinformationsearch } = useParams();
  const { isSourceSidebarOpen } = useSelector((state) => state.data);
  const { acceptedMISTerms } = useSelector((state) => state.data);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const getNewsCardData = async (searchTerm) => {
    setLoading(true);

    if (searchTerm == null) {
      setNewsCards([]);
      setLoading(false);
      return;
    }

    const newsDataObj = await fetchNewsData(
      searchTerm,
      user,
      null,
      "Misinformation"
    );
    setNewsCards(newsDataObj);
    setLoading(false);
  };

  const handleSearch = (searchTerm) => {
    navigate(`/tools/misinformation-search/${encodeURI(searchTerm)}`);
  };

  useEffect(() => {
    getNewsCardData(misinformationsearch);
    if (!misinformationsearch) {
      handleSearch("");
    }
  }, [misinformationsearch]);

  const getData = async () => {
    try {
      setGetUserDataLoading(true);

      if (!user || !user.userId || !user.token) {
        throw new Error("User information is incomplete.");
      }

      const { data } = await Axios.get(
        `${authAPI.getUserDetails}?userId=${user.userId}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      console.log("Data", data);
      dispatch(setAcceptedMISTerms(data.acceptedMISTerms));
      console.log(data.acceptedMISTerms);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setGetUserDataLoading(false);
    }
  };

  const sourceSidebarClosedHandler = () => {
    dispatch(setIsSourceSidebarOpen(false));
    document.body.style.overflow = "unset";
  };

  const showSourceSidebar = () => {
    dispatch(setIsSourceSidebarOpen(true));

    if (typeof window !== "undefined" && window.document) {
      document.body.style.overflow = "hidden";
    }
  };

  const formatFormData = () => {
    const formData = new FormData();
    formData.append("AcceptedMISTerms", true);

    return formData;
  };

  const updateProfile = async () => {
    try {
      const formData = formatFormData();

      const { data } = await axiosInstance.post(
        authAPI.updateProfile,
        formData,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "multipart/form-data",
          },
        },
        { cache: false }
      );

      getData();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <React.Fragment>
      {getDataLoading ? (
        <Skeleton height={90} />
      ) : !acceptedMISTerms ? (
        <React.Fragment>
          <Alert severity="warning">
            <AlertTitle>CAUTION</AlertTitle>
            <Typography variant="body2">
              You must acknowledge the following warning before proceeding.
            </Typography>
            <Typography mt={1} variant="body2">
              This unique Misinformation Search tool is comprised solely of
              known sources of misinformation based on NewsGuard’s assessment
              criteria. Specifically, all of these sources{" "}
              <Typography variant="body2" component="span" fontWeight="bold">
                FAIL
              </Typography>{" "}
              NewsGuard’s primary Credibility metric “Does not regularly publish
              false or egregiously misleading information.”
            </Typography>
            <Typography mt={1} variant="body2">
              Please use extreme caution when consuming content published by
              these sources as they often times may incorporate factually
              accurate information alongside misinformation in order to confuse
              and disorient the reader.
            </Typography>
            <Typography mt={1} variant="body2">
              This Misinformation Search tool is designed for educational and
              research purposes only. If you have any comments or concerns
              please contact us at{" "}
              <Link href="mailto:contact@sooth.fyi">contact@sooth.fyi</Link> or
              use the feedback button.
            </Typography>
          </Alert>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button sx={{ my: 1 }} onClick={() => updateProfile()}>
              Agree
            </Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography variant="caption">
            Misinformation Search is a one-of-a-kind research tool that allows
            you to search exclusively across known sources of false or
            misleading information. This powerful tool should be used with
            extreme caution and is for educational and research purposes only.
          </Typography>
          <ToolkitSearchBar
            placeholder="Search low-credibility sources ..."
            handleSearch={handleSearch}
            buttonText="Search"
            value={misinformationsearch}
          />
          <Alert
            variant="filled"
            severity="warning"
            iconMapping={{
              warning: <WarningIcon sx={{ color: "red" }} />,
            }}
            sx={{ my: 1, bgcolor: "#feeae0", color: "black", p: 1 }}
          >
            These results are exclusively from known sources of false or
            egregiously misleading information.
          </Alert>
          {loading ? (
            <Box>
              <Skeleton height={90} />
              <Skeleton height={90} />
              <Skeleton height={90} />
            </Box>
          ) : (
            newsCards.map((card) => (
              <TableRow
                row={card}
                menuClick={() => {}}
                key={card.id}
                isPublicCollectionSrc={true}
                isMisinformation={true}
              />
            ))
          )}
          <SourceSidebar
            isSourceSidebarOpen={isSourceSidebarOpen}
            sourceSidebarClosedHandler={sourceSidebarClosedHandler}
            showSourceSidebar={showSourceSidebar}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
