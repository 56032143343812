import { Box, Link, Typography } from "@mui/material";

export default function Terms({ logEvent, wordpressURL }) {
  return (
    <Box>
      <Typography variant="body2">
        By signing in to Sooth.fyi, you agree to our{" "}
        <Link
          href={`https://sooth.fyi/terms-of-use/`}
          target="_blank"
          rel="noreferrer"
          onClick={(e) =>
            logEvent(`click_footer_terms`, { ["terms"]: "clicked" })
          }
        >
          Terms of Use
        </Link>{" "}
        and{" "}
        <Link
          href={`https://sooth.fyi/privacy/`}
          target="_blank"
          rel="noreferrer"
          onClick={(e) =>
            logEvent(`click_footer_privacy`, { ["privacy"]: "clicked" })
          }
        >
          Privacy Policy
        </Link>
        .
      </Typography>
    </Box>
  );
}
