import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

import { setupInterceptorsTo } from "lib/axios";
import { logoutUser } from "context/features/authSlice";

import SplashScreen from "components/layout/SplashScreen";
import Layout from "components/layout/Layout";
import ProfileLayout from "components/layout/ProfileLayout";
import CollectionsLayout from "components/layout/CollectionsLayout";
import LoginSignUpLayout from "components/layout/LoginSignUpLayout";
import ToolsLayout from "components/layout/ToolsLayout";
import PublicCollectionPage from "features/PublicCollection";

import Landing from "views/Landing";
import NewsPage from "views/Search/Newspage";
import NewsCategoryPage from "views/Search/NewsCategoryPage";
import ProfileIndexPage from "views/Profile/ProfileIndexPage";
import InfoDietPage from "views/Profile/InfoDietPage";
import SummarizerPage from "views/Tools/SummarizerPage";
import DomainCheckerPage from "views/Tools/DomainCheckerPage";
import MisinformationFingerprintsPage from "views/Tools/MisinformationFingerprintsPage";
import MisinformationSearchPage from "views/Tools/MisinformatinSearchPage";
import CollectionIndex from "views/Collections";
import Collection from "views/Collections/Collection";
import PublicIndexPage from "views/Collections/PublicIndex";
import Error404 from "views/404";
import SignupForm from "views/SignupForm";
import ForgotPassword from "views/ForgotPassword";
import Login from "views/Login";
import EmailConfirm from "views/EmailConfirm/EmailConfirm";
import EmailMessage from "views/EmailConfirm/EmailMessage";
import ResetPassword from "views/ResetPassword";
import OauthLogin from "views/MicrosoftLogin";
import CleverLoggedIn from "views/CleverLoggedIn";
import ClassLinkLogin from "views/ClasslinkLogin";
import MisinformationFingerprintsDetailsPage from "views/Tools/MisinformationFingerprintsDetailsPage";
import AdminPage from "views/AdminPage";
import SearchSourceSwitch from "views/Search/SearchSourceSwitch";
import SubscriptionPage from "views/Profile/Subscription/SubscriptionPage";
import IndividualSignUpPage from "views/Profile/Subscription/IndividualSignUpPage";
import PaymentStatusPage from "views/Profile/Subscription/PaymentStatus";
import { useUserData } from "features/User";
import PulseLayout from "components/layout/PulseLayout";
import PulseHomePage from "views/Search/PulseHomePage";
import SubscriptionAgreementPage from "views/Profile/Subscription/SubscriptionAgreement";
import PrefrencesPage from "views/Profile/PrefrencesPage";
import GroupUserManagement from "views/Profile/GroupManagement/UserManagement";
import GroupControlsPage from "views/Profile/GroupManagement/GroupControls";
import AfpPage from "views/Search/AfpPage";

export default function Router() {
  const dispatch = useDispatch();
  const { hash } = useLocation();

  const {
    isSubscriptionExpired,
    getSubscriptionInfo,
    getUserInfo,
    updateReduxState,
    isSubscriptionLoading,
    isAuthenticated,
    userLoading,
  } = useUserData();

  const logoutUserFromApp = () => {
    dispatch(logoutUser());
  };

  const getUserDetails = async () => {
    try {
      await getUserInfo();
      await getSubscriptionInfo();
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    setupInterceptorsTo(
      updateReduxState,
      logoutUserFromApp,
      isSubscriptionExpired
    );
  });

  useEffect(() => {
    getUserDetails();
  }, [isAuthenticated]);

  useEffect(() => {
    const element = document.getElementById(hash.substring(1));
    if (element != null) {
      element.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [hash]);

  return (
    <React.Fragment>
      {userLoading || isSubscriptionLoading ? (
        <SplashScreen />
      ) : !isAuthenticated ? (
        <Routes>
          <Route path="/" element={<LoginSignUpLayout />}>
            <Route index element={<Login />} />
            <Route path="signin/microsoft" element={<OauthLogin />} />
            <Route path="signin/clever" element={<OauthLogin />} />
            <Route path="signin/classlink" element={<ClassLinkLogin />} />
            <Route path="signup" element={<SignupForm />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route
              path="SignupMessage"
              element={<EmailMessage type="signup" />}
            />
            <Route
              path="ResetPasswordMessage"
              element={<EmailMessage type="resetpassword" />}
            />
            <Route path="ConfirmEmail" element={<EmailConfirm />} />
            <Route path="ResetPassword" element={<ResetPassword />} />
            <Route path="*" element={<Navigate redirect to="/" />} />
          </Route>
        </Routes>
      ) : isSubscriptionExpired ? (
        <Routes>
          <Route path="/profile" element={<ProfileLayout />}>
            <Route
              index
              element={<Navigate redirect to="/profile/subscription" />}
            />
            <Route path="subscription">
              <Route index element={<SubscriptionPage />} />
              <Route
                path="individual-plan"
                element={<IndividualSignUpPage />}
              />
              <Route path="payment-status" element={<PaymentStatusPage />} />
              <Route
                path="subscriber-agreement"
                element={<SubscriptionAgreementPage />}
              />
            </Route>

            <Route
              path="*"
              element={<Navigate redirect to="/profile/subscription" />}
            />
          </Route>
          <Route
            path="*"
            element={<Navigate redirect to="/profile/subscription" />}
          />
        </Routes>
      ) : (
        <Routes>
          <Route index element={<Landing />} />
          <Route path="/afp-article/:afpId" element={<AfpPage />} />

          <Route path="/pulse" element={<PulseLayout />}>
            <Route index element={<PulseHomePage />} />
            <Route path="label/:category" element={<PulseHomePage />} />
          </Route>

          <Route path="/collections" element={<CollectionsLayout />}>
            <Route index element={<CollectionIndex />} />
            <Route path=":id" element={<Collection />} />
            <Route path="public" element={<PublicIndexPage />} />
            <Route path="public/:id" element={<PublicCollectionPage />} />
          </Route>
          <Route path="/profile" element={<ProfileLayout />}>
            <Route index element={<ProfileIndexPage />} />
            <Route path="info-diet" element={<InfoDietPage />} />
            <Route path="sooth-admin" element={<AdminPage />} />
            <Route path="preferences" element={<PrefrencesPage />} />
            <Route path="subscription">
              <Route index element={<SubscriptionPage />} />
              <Route
                path="individual-plan"
                element={<IndividualSignUpPage />}
              />
              <Route path="payment-status" element={<PaymentStatusPage />} />
              <Route
                path="subscriber-agreement"
                element={<SubscriptionAgreementPage />}
              />
            </Route>
            <Route path="group">
              <Route path="user-management" element={<GroupUserManagement />} />
              <Route path="group-controls" element={<GroupControlsPage />} />
              <Route
                path="*"
                element={
                  <Navigate redirect to="/profile/group/user-management" />
                }
              />
            </Route>

            <Route path="*" element={<Navigate redirect to="/profile" />} />
          </Route>
          <Route path="/tools" element={<ToolsLayout />}>
            <Route
              index
              element={<Navigate redirect to="/tools/summarizer" />}
            />
            <Route path="summarizer" element={<SummarizerPage />} />
            <Route path="domain-checker" element={<DomainCheckerPage />} />
            <Route
              path="domain-checker/:domainName"
              element={<DomainCheckerPage />}
            />
            <Route
              path="misinformation-fingerprints"
              element={<MisinformationFingerprintsPage />}
            />
            <Route
              path="misinformation-fingerprints/:fingerprintSearch"
              element={<MisinformationFingerprintsPage />}
            />
            <Route
              path="misinformation-fingerprints/details/:id"
              element={<MisinformationFingerprintsDetailsPage />}
            />
            <Route
              path="misinformation-search"
              element={<MisinformationSearchPage />}
            />
            <Route
              path="misinformation-search/:misinformationsearch"
              element={<MisinformationSearchPage />}
            />
          </Route>

          {/* <Route path="/admin" element={<AdminLayout />}>
            <Route index element={<AdminPage />} />
          </Route> */}

          {/* Why is this here? */}
          <Route path="/signin/clever" element={<CleverLoggedIn />} />
          <Route path="/search/:search" element={<Layout />}>
            <Route index element={<NewsPage />} />
            <Route path=":source" element={<SearchSourceSwitch />} />
            <Route path=":source/:category" element={<NewsCategoryPage />} />
            <Route
              path=":source/:category/:academiaSearch"
              element={<NewsCategoryPage />}
            />
          </Route>

          <Route path="*" element={<Error404 />} />
        </Routes>
      )}
    </React.Fragment>
  );
}
