import { useEffect, useState } from "react";
import { fetchNewsData } from "../services/news.service";
import { useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { getFilterDateValue } from "utils/date-filter.util";

const useNewsData = (setloading, category, query) => {
  const [newsCards, setNewsCards] = useState({});
  const { user } = useSelector((state) => state.auth);

  const { fromDate, toDate } = useSelector((state) => state.data);
  const [searchParams] = useSearchParams();
  const { search } = useParams();

  const filterTerm = searchParams.get("pubDate");
  const filteredDateValue = getFilterDateValue(filterTerm, fromDate, toDate);

  const fetchCategoryDataNewsCards = async () => {
    setloading(true);

    const newsDataObj = await fetchNewsData(
      query,
      user,
      filteredDateValue,
      category
    );
    setNewsCards(newsDataObj);
    setloading(false);
  };

  useEffect(() => {
    fetchCategoryDataNewsCards();
  }, [search, filteredDateValue]);

  return { newsCards, setNewsCards };
};

export default useNewsData;
