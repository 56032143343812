import React, { useState } from "react";
import {
  Button,
  IconButton,
  Box,
  TextField,
  Typography,
  MenuItem,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useCollection from "features/Collections/hooks/use-collection.hook";
import {
  setIsBookmarkSidebar,
  setIsSummaryAvailable,
} from "context/features/dataSlice";
import { toast } from "react-toastify";

const ExternalBookmark = ({ toggleSidebar }) => {
  const params = useParams();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const { handleSaveCustomBookmark } = useCollection(setLoading);
  const [bookmark, setBookmark] = useState({
    sourceName: "",
    title: "",
    sourceURL: "",
    sourcePublishedDateTime: "",
  });

  const theme = useTheme();
  const navbarBackground = theme.palette?.navbar?.main;

  const [selectedCollection, setSelectedCollection] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const { collections, isSummaryAvailable, articleSummary } = useSelector(
    (state) => state.data
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    setBookmark((prevBookmark) => ({
      ...prevBookmark,
      [name]: value,
    }));
  };

  const format_response = () => {
    let commonResponse = {
      callingUserId: user.userId,
      userId: user.userId,
      collectionId: params.id,
      ...bookmark,
      sourcePublishedDateTime: new Date(bookmark.sourcePublishedDateTime),
      title: bookmark.title.length === 0 ? bookmark.sourceURL : bookmark.title,
      summary: articleSummary,
      ...(isSummaryAvailable && { collectionId: selectedCollection }),
    };
    return commonResponse;
  };

  const handleSave = async () => {
    const json_res = format_response();

    if (!bookmark.sourceURL) {
      toast.error("URL cannot be empty!");
      return;
    }

    await handleSaveCustomBookmark(bookmark, json_res);

    setBookmark({
      sourceName: "",
      title: "",
      sourceURL: "",
      sourcePublishedDateTime: "",
    });
    dispatch(setIsSummaryAvailable(false));
  };

  return (
    <Box height={"100%"} sx={{ position: "relative" }}>
      <Box
        sx={{
          backgroundColor: navbarBackground,
          p: 3,
          position: "sticky",
          top: 0,
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: navbarBackground,
            alignItems: "center",
          }}
        >
          <Typography variant="h5" sx={{ color: "#696b6c", fontSize: "2rem" }}>
            Save Bookmark
          </Typography>
          <IconButton onClick={() => toggleSidebar()}>
            <CloseIcon style={{ height: 18, width: 18 }} />
          </IconButton>
        </Box>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 0.8, p: 2 }}>
        <Typography variant="body1" sx={{ mt: 2 }}>
          Please fill out the details for this bookmark.
        </Typography>
        <TextField
          label="Title"
          name="title"
          sx={{ width: "100%", mb: 2, mt: 2 }}
          value={bookmark.title}
          onChange={handleChange}
        />
        <TextField
          label="Source Name"
          name="sourceName"
          sx={{ width: "100%", mb: 2 }}
          value={bookmark.sourceName}
          onChange={handleChange}
        />
        <TextField
          label="Source URL"
          name="sourceURL"
          sx={{ width: "100%", mb: 2 }}
          value={bookmark.sourceURL}
          onChange={handleChange}
        />
        <TextField
          label="Published Date"
          name="sourcePublishedDateTime"
          type="date"
          sx={{ width: "100%" }}
          value={bookmark.sourcePublishedDateTime}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />

        {isSummaryAvailable && (
          <TextField
            select
            label={"Add to Collection..."}
            value={selectedCollection}
            onChange={(e) => {
              setSelectedCollection(e.target.value);
            }}
            variant="outlined"
            SelectProps={{
              open: dropdownOpen,
              onOpen: () => setDropdownOpen(true),
              onClose: () => setDropdownOpen(false),
              onClick: (event) => event.stopPropagation(),
            }}
            sx={{
              mt: 1.5,
            }}
          >
            {collections.length > 0 ? (
              collections.map((collection) => (
                <MenuItem
                  key={collection.collectionId}
                  value={collection.collectionId}
                >
                  {collection.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No collections available</MenuItem>
            )}
          </TextField>
        )}
      </Box>
      <Box
        sx={{
          backgroundColor: navbarBackground,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          p: 2,
          position: "absolute",
          bottom: 0,
        }}
      >
        <Button
          onClick={() => toggleSidebar()}
          variant="outlined"
          sx={{ width: "45%" }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          sx={{
            width: "45%",
          }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default ExternalBookmark;
