import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CategorySection } from "./components/section-category";
import LoaderPage from "components/layout/LoaderPage";
import useCategoryData from "./hooks/use-category-data.hook";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

export default function News() {
  const [loading, setLoading] = useState(true);
  const { fetchNewsCategoryData } = useCategoryData(setLoading);

  const { categories } = useSelector((state) => state.data);

  const { search, source } = useParams();
  const location = useLocation();

  const getQueryParams = (query) => {
    return new URLSearchParams(query);
  };

  const queryParams = getQueryParams(location.search);
  const pubDate = queryParams.get("pubDate");

  function checkedSource(source) {
    return source == null ? "categories" : source;
  }

  useEffect(() => {
    fetchNewsCategoryData();
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LoaderPage />
      </Box>
    );
  }

  return (
    <React.Fragment>
      {categories.map((category) => {
        return (
          <Box key={`${checkedSource(source)}-${category.category_id}`}>
            <CategorySection
              query={search}
              category={category}
              source={checkedSource(source)}
              pubDate={pubDate}
            />
          </Box>
        );
      })}
    </React.Fragment>
  );
}
