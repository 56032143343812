import React, { useMemo, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { Box, useTheme } from "@mui/material";
//import "./styles.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import { stateToMarkdown } from "draft-js-export-markdown";

import { ReturnDownIcon } from "assets/icons/ReturnDownIcon";

const EditorBox = ({
  isFocused,
  setIsFocused,
  handleSave,
  editorState,
  setEditorState,
  summaryEditor,
  saveNote,
  handleBlur,
}) => {
  const theme = useTheme();
  const [cursor, setCursor] = useState(true);

  const editorRef = useRef(null);

  const onEditorStateChange = (newEditorState) => {
    if (newEditorState !== editorState) {
      setEditorState(newEditorState);
    }
  };

  const handleClick = () => {
    const contentState = editorState.getCurrentContent();
    const html = stateToMarkdown(contentState);
    handleSave(html);
  };

  const toolbar = {
    options: [
      "emoji",
      "inline",
      "blockType",
      "fontSize",
      "list",
      // "link",
      "history",
    ],
    inline: {
      options: ["bold", "italic"],
    },
    blockType: {
      options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
    },
    list: {
      options: ["unordered", "ordered"],
    },
  };

  const handleKeyDown = (event) => {
    console.log(event);
    if (event === "split-block") {
      handleClick();
      handleBlur();
    }
  };

  const handleEditorBlur = (ref) => {
    if (saveNote) {
      handleClick();
    }
  };

  const setEditorReference = (ref) => {
    if (!cursor) return;
    if (ref) {
      editorRef.current = ref;
      editorRef.current.focus();
      setCursor(false);
    }
  };

  const editorStyles = useMemo(
    () => ({
      ".wrapper-class": {
        position: "relative",
        padding: "0.5rem",
        border: "1px solid #ccc",
        borderRadius: "15px",
      },

      ".editor-class": {
        minHeight: "72px",
        overflow: "auto",
        maxHeight: "72px",
        backgroundColor: "transparent",
        marginBottom: "2rem",
        padding: "0.5rem",
        paddingBottom: "1rem",
        color: theme.palette.text.primary,
        "&.height-50": {
          maxHeight: "unset",
          height: "58vh",
          overflowY: "auto",
        },
        "& ul, & ol": {
          paddingLeft: "10px",
          listStylePosition: "inside",
        },
        "& li": {
          position: "relative",
          alignItems: "flex-start",
        },
      },

      ".toolbar-class": {
        background: "transparent",
        border: "none",
        transition: "all 0.3s ease",
        opacity: 1,
        "& .rdw-option-wrapper, & .rdw-dropdown-wrapper": {
          backgroundColor: "transparent",
          color: theme.palette.text.primary,
          border: "none",
          "&:hover, &.rdw-option-active": {
            boxShadow: "none",
          },
          "&:hover": {
            backgroundColor: theme.palette.secondary.dark,
          },
          "&:not(:hover).rdw-option-active": {
            backgroundColor: theme.palette.secondary.main,
          },
          "& .rdw-dropdown-optionwrapper": {
            backgroundColor: theme.palette.background.default,
            border: "none",
            "&:hover": {
              boxShadow: "none",
            },
            "& .rdw-dropdownoption-default:hover": {
              backgroundColor: theme.palette.secondary.dark,
            },
            "& .rdw-dropdownoption-default:not(:hover).rdw-dropdownoption-active":
              {
                backgroundColor: theme.palette.secondary.main,
              },
          },
        },
      },

      ".wrapper-class-noborder": {
        borderRadius: "4px",
      },

      ".toolbar-hidden": {
        opacity: 0,
        height: 0,
        margin: 0,
        visibility: "hidden",
        pointerEvents: "none",
      },

      ".toolbar-visible": {
        opacity: 1,
        zIndex: 1,
      },
    }),
    [theme]
  );
  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 1000,
        height: summaryEditor ? "60%" : "20%",
        backgroundColor: "transparent",
        ...editorStyles,
      }}
      pb={0.5}
    >
      <Editor
        editorStyle={{ textAlign: "left" }}
        toolbarHidden={!isFocused}
        editorRef={setEditorReference}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        toolbar={toolbar}
        wrapperClassName="wrapper-class"
        editorClassName={`${
          summaryEditor ? "editor-class height-50" : "editor-class"
        }`}
        toolbarClassName={`toolbar-class`}
        placeholder="Add a note..."
        onKeyDown={handleKeyDown}
        handleKeyCommand={handleKeyDown}
        onBlur={handleEditorBlur}
      />
      <ReturnDownIcon
        onClick={() => {
          handleClick();
          handleBlur();
        }}
        style={{
          width: 18,
          height: 22,
          position: "absolute",
          right: 20,
          bottom: 10,
          cursor: "pointer",
        }}
      />

      <FormatColorTextIcon
        onClick={() => setIsFocused(!isFocused)}
        style={{
          width: 18,
          height: 22,
          position: "absolute",
          right: 45,
          bottom: 15,
          cursor: "pointer",
        }}
      />
    </Box>
  );
};

export default EditorBox;
