import { LockClockOutlined, MoreHorizOutlined } from "@mui/icons-material";
import {
  Stack,
  Link,
  Box,
  Typography,
  Divider,
  TextField,
  Tooltip,
} from "@mui/material";
import NewsguardScore from "components/base/NewsguardScore";
import AllSidesRating from "components/base/all-sides/AllSidesRating";
import { useIsTab } from "hooks/is-tab.hook";
import Markdown from "features/CategoryNews/component/MarkdownNote";
import { HiOutlineLockOpen } from "react-icons/hi2";
import { VscLock } from "react-icons/vsc";
import { citationDate } from "utils/time.util";
import { useDispatch } from "react-redux";
import {
  setEntityName,
  setIsSourceSidebarOpen,
  setSourceUrl,
} from "context/features/dataSlice";
import { logEvent } from "services/amplitude.service";

export default function TableRow({
  row,
  menuClick,
  isPublicCollectionSrc,
  ...otherProps
}) {
  const isTab = useIsTab();
  const dispatch = useDispatch();

  const openSourceSidebar = () => {
    logEvent("click_card_header", {
      host: row.hostName,
      entity: row.categoryName_chr,
    });
    dispatch(setIsSourceSidebarOpen(true));
    dispatch(setSourceUrl(row.hostName));
    dispatch(setEntityName(row.sourceName_chr));
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateAreas: !isTab ? '"bookmark divide notes"' : '"bookmark"',
        gridTemplateColumns:
          !isTab && !isPublicCollectionSrc ? "70% auto 25%" : "1fr",
        "&:hover .enterNoteField": {
          opacity: 1,
        },
        width: {
          xs: "90vw",
          sm: "unset",
        },
      }}
      {...otherProps}
    >
      <Stack
        direction="column"
        gap={1}
        justifyContent="space-between"
        sx={{
          maxWidth: "100%",
          minHeight: "10.2vh",
          gridArea: "bookmark",
          pt: 2,
        }}
      >
        <Stack
          direction="row"
          gap={1.5}
          sx={{
            alignItems: { xs: "flex-start", md: "center" },
            maxWidth: "100% ",
          }}
        >
          <Typography
            component="div"
            variant="subtitle1"
            noWrap={!isTab}
            sx={{
              flexGrow: 1,
              minWidth: 0,
              overflowWrap: isTab ? "anywhere" : "unset",
              maxWidth: "90%",
            }}
          >
            <Link href={row.sourceUrl_chr} target="_blank">
              {row.title_chr}
            </Link>
          </Typography>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems={{ xs: "flex-end", sm: "center" }}
          >
            {row.mediaBiasRating && (
              <AllSidesRating activeCategory={row.mediaBiasRating} />
            )}
            {row.score && (
              <NewsguardScore score={Number(row.score)} size="small" />
            )}
            <Box>
              {row.hasPayWall && row.isPartner ? (
                <Tooltip title="We removed the paywall for you!">
                  <Box sx={{ cursor: "pointer" }}>
                    <HiOutlineLockOpen />
                  </Box>
                </Tooltip>
              ) : (
                row.hasPayWall && (
                  <Tooltip
                    placement="bottom-start"
                    title="This source may limit your access without a subscription"
                  >
                    <Box sx={{ cursor: "pointer" }}>
                      <VscLock />
                    </Box>
                  </Tooltip>
                )
              )}
            </Box>
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between" gap={1}>
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => {
              openSourceSidebar();
            }}
          >
            <Typography
              component="span"
              variant="body2"
              noWrap
              fontWeight="bold"
            >
              {row.sourceName_chr}
            </Typography>
            <Typography component="span" variant="body2" color="text.secondary">
              - {citationDate(row.sourcePublishedDateTime_dtm)}
            </Typography>
          </Box>
          <Box>
            <MoreHorizOutlined
              onClick={() => menuClick(row)}
              sx={{ color: "grey", width: 18, cursor: "pointer" }}
            />
          </Box>
        </Stack>
        <Divider flexItem />
      </Stack>
      {!isTab && !isPublicCollectionSrc && (
        <Divider
          orientation="vertical"
          flexItem
          sx={{ gridArea: "divide", justifySelf: "center" }}
        />
      )}
      {!isTab && !isPublicCollectionSrc && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gridArea: "notes",
          }}
        >
          {row.notes != null && row.notes.length > 0 ? (
            <Markdown item={row} note={row.notes[0]} onClick={menuClick} />
          ) : (
            <TextField
              className="enterNoteField"
              sx={{
                width: "100%",
                my: "auto",
                opacity: 0,
                transition: "all .4s ease",
              }}
              onClick={() => {
                menuClick(row);
              }}
              label="Enter Note"
              variant="outlined"
            />
          )}
        </Box>
      )}
    </Box>
  );
}
