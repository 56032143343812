import { Box, Typography, useTheme } from "@mui/material";

export default function ProfileQuickBox({ children, title, description }) {
    const theme = useTheme();
  return (
    <Box
      sx={{
        width: {xs: "100%", sm: "30%"},
        bgcolor: "secondary.main",
        p: 4,
        borderRadius: theme.shape.borderRadius / 4,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Typography variant="h4" color="primary.main">
        {title}
      </Typography>
      <Typography variant="body2">{description}</Typography>
      <Box sx={{ marginLeft: "auto", mt: "auto" }}>{children}</Box>
    </Box>
  );
}
