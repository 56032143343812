import { createTheme } from "@mui/material";
import freighttextprowoff2 from "./assets/fonts/FreightTextProMedium-Regular.woff2";
import freighttextprowoff from "./assets/fonts/FreightTextProMedium-Regular.woff";
import freighttextprottf from "./assets/fonts/FreightTextProMedium-Regular.ttf";
import freighttextproitalicwoff2 from "./assets/fonts/FreightTextProMedium-Italic.woff2";
import freighttextproitalicwoff from "./assets/fonts/FreightTextProMedium-Italic.woff";
import freighttextproitalicttf from "./assets/fonts/FreightTextProMedium-Italic.ttf";

const InterFont = ["Inter", "sans-serif"];
const FreightTextProFont = ["freighttextpro", "sans-serif"];
const FreightTextProItalicFont = ["freighttextproitalic", "sans-serif"];

const lightPalette = {
  primary: {
    main: "#406da4",
  },
  secondary: {
    main: "#E6EEF2",
    dark: "#CFDDE5",
  },
  text: {
    primary: "#555",
  },
};

const darkPalette = {
  primary: {
    main: "#CFDDE5",
  },
  secondary: {
    main: "#161E22",
    dark: "#222E37",
  },
};

export function setupTheme(mode) {
  const palette =
    mode === "dark"
      ? {
          mode,
          ...darkPalette,
        }
      : {
          mode,
          ...lightPalette,
        };
  return createTheme({
    palette,
    shape: {
      borderRadius: 10,
    },
    typography: {
      htmlFontSize: 10,
      fontFamily: InterFont.join(","),
      h1: {
        fontFamily: FreightTextProFont.join(","),
      },
      h2: {
        fontFamily: FreightTextProFont.join(","),
      },
      h3: {
        fontFamily: FreightTextProFont.join(","),
      },
      h4: {
        fontFamily: FreightTextProItalicFont.join(","),
        fontSize: "2.5rem",
      },
      h5: {
        fontSize: "1.7rem",
        fontFamily: FreightTextProItalicFont.join(","),
      },
      h6: {
        fontFamily: FreightTextProItalicFont.join(","),
        fontSize: "1.6rem",
        fontWeight: 600,
      },
      contentTitle: {
        fontSize: "3rem",
      },
      body1: {
        fontSize: "1.4rem",
      },
      body2: {
        fontSize: "1.2rem",
      },
      body3: {
        fontSize: "1.25rem",
      },
      body4: {
        fontSize: "1.6rem",
      },
      buttonFont: {
        fontFamily: FreightTextProFont.join(","),
        fontSize: "1.2rem",
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: ({ typography, palette, shape }) => {
          return `
                @font-face {
                  font-family: 'freighttextpro';
                  src: local('FreightText Pro  Medium Regular'), local('FreightText-Pro--Medium-Regular'),
                      url(${freighttextprowoff2}) format('woff2'),
                      url(${freighttextprowoff}) format('woff'),
                      url(${freighttextprottf}) format('truetype');
                  font-weight: 400;
                  font-style: normal;
                }
                @font-face {
                  font-family: 'freighttextproitalic';
                  src: local('FreightText Pro  Medium Italic'), local('FreightText-Pro--Medium-Italic'),
                      url(${freighttextproitalicwoff2}) format('woff2'),
                      url(${freighttextproitalicwoff}) format('woff'),
                      url(${freighttextproitalicttf}) format('truetype');
                  font-weight: 500;
                  font-style: italic;
                }
                :root {
                  --toastify-color-dark: ${palette.secondary.main};
                  --toastify-color-info: ${palette.info.main};
                  --toastify-color-success: ${palette.success.main};
                  --toastify-color-warning: ${palette.warning.main};
                  --toastify-color-error: ${palette.error.main};

                  --toastify-font-family: ${typography.fontFamily};
                }           
                html {
                  font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%
                }
                *::-webkit-scrollbar {
                  height: 8px;
                  width: 8px;
                }
                *::-webkit-scrollbar-track {
                  border-radius: 4px;
                  background-color: ${palette.secondary.main};
                }
                
                *::-webkit-scrollbar-track:hover {
                  background-color: ${palette.secondary.dark};
                }
                
                *::-webkit-scrollbar-track:active {
                  background-color: ${palette.secondary.dark};
                }
                
                *::-webkit-scrollbar-thumb {
                  border-radius: 5px;
                  background-color: ${palette.primary.main};
                }
                
                *::-webkit-scrollbar-thumb:hover {
                  background-color: ${palette.primary.dark};
                }
                
                *::-webkit-scrollbar-thumb:active {
                  background-color: ${palette.primary.dark};
                }
                .Toastify__toast-container {
                  padding-top: 75px;
                }
                .Toastify__toast {
                  border-radius: ${shape.borderRadius}px;
                }
                .Toastify__toast-theme--dark {
                  border-width: 1px;
                  border-color: ${palette.divider};
                  border-style: solid;
                }
                .side-nav-link {
                  display: flex;
                  align-items: center;
                  justify-content: start;
                  height: 3.5rem;
                  border-top-left-radius: 1.75rem;
                  border-bottom-left-radius: 1.75rem;
                  color: inherit;
                  padding: 0 2rem;
                  text-decoration: none;
                  position: relative;
                  &.active,
                  &:hover {
                    background-color: ${palette.secondary.dark};
                    color: ${palette.getContrastText(palette.secondary.dark)};
                  }
                  &.active {
                    &:after {
                      display: block;
                      content: "";
                      position: absolute;
                      top: 0;
                      bottom: 0;
                      right: 0;
                      width: 0.3rem;
                      background-color: ${palette.primary.main};
                    }
                  }
                }
                
                .main-nav-link {
                  display: flex;
                  padding: 1.5rem;
                  padding-top: 2.5rem;
                  position: relative;
                  &.landing-page:hover {
                    background-color: transparent;
                  }
                  &.active,
                  &:hover {
                    background-color: ${palette.secondary.dark};
                    > svg {
                      fill: ${palette.getContrastText(palette.secondary.dark)};
                    }
                  }
                  &.active {
                    &:after {
                      display: block;
                      content: "";
                      position: absolute;
                      left: 0;
                      bottom: 0;
                      right: 0;
                      height: 0.3rem;
                      background-color: ${palette.primary.main};
                    }
                  }
                }
              `;
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 20,
            fontFamily: FreightTextProItalicFont.join(","),
            fontWeight: "bold",
            textTransform: "none",
            "&.MuiButton-sizeMedium": {
              fontSize: "1.6rem",
            },
          },
        },
        defaultProps: {
          variant: "contained",
          disableElevation: true,
        },
        variants: [
          {
            props: { variant: "chip" },
            style: ({ theme }) => ({
              backgroundColor: theme.palette.secondary.dark,
            }),
          },
        ],
      },
      MuiButtonGroup: {
        defaultProps: {
          variant: "contained",
          disableElevation: true,
          size: "small",
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            borderRadius: 20,
          },
        },
        defaultProps: {
          size: "small",
          color: "primary",
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            borderRadius: 20,
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: "outlined",
          size: "small",
        },
      },
      MuiChip: {
        variants: [
          {
            props: { variant: "filter" },
            style: ({ theme }) => ({
              backgroundColor: theme.palette.secondary.dark,
            }),
          },
        ],
      },
      MuiIconButton: {
        variants: [
          {
            props: { variant: "chip" },
            style: ({ theme }) => ({
              backgroundColor: theme.palette.secondary.dark,
            }),
          },
        ],
      },
      MuiLink: {
        defaultProps: {
          underline: "none",
        },
      },
      MuiStack: {
        defaultProps: {
          gap: 2,
        },
      },
    },
  });
}
