import React, { useEffect } from "react";
import { Box, Divider, TextField } from "@mui/material";
import getCardComponent from "./NewsCard";
import { useSelector } from "react-redux";
import { useIsTab } from "hooks/is-tab.hook";
import Markdown from "features/CategoryNews/component/MarkdownNote";
import { useDispatch } from "react-redux";
import {
  setCollectionId,
  setCollectionSourceId,
  setIsCitationAvailable,
  setIsSidebarOpen,
  setIsSourceSidebarOpen,
  setIsSummaryAvailable,
  setNote,
  setSelectedBookmarkOption,
  setSummaryInfoData,
} from "context/features/dataSlice";
import { useParams } from "react-router-dom";

const SectionCategory = ({
  categoryData,
  lastElementRef,
  categoryType,
  setcategoryData,
}) => {
  const { category, search } = useParams();
  const { academiaSearch } = useParams();

  const NewsCard = getCardComponent(categoryType, academiaSearch);
  const isTab = useIsTab();
  const dispatch = useDispatch();

  const { bookmarkedData, academiaSearchList } = useSelector(
    (state) => state.data
  );

  const menuClick = (item) => {
    dispatch(setSelectedBookmarkOption("notes"));
    dispatch(setNote(item?.notes ? item.notes[0] : { noteId: null }));
    if (!academiaSearch) {
      dispatch(
        setSummaryInfoData({
          publisher: item.entityName,
          title: item.name,
          published_date: item.datePublishedDisplayText,
          url: item.url,
        })
      );
    } else {
      dispatch(
        setSummaryInfoData({
          publisher: academiaSearch,
          title: item.title,
          published_date: item.datePublished,
          url: item.url,
        })
      );
    }

    dispatch(setCollectionSourceId(item.collectionSourceId));
    dispatch(setCollectionId(item.collectionId));
    dispatch(setIsCitationAvailable(item.isCitationAvailable));
    dispatch(setIsSummaryAvailable(item.isSummaryAvailable));
    dispatch(setIsSidebarOpen(true));
  };

  useEffect(() => {
    if (bookmarkedData.id && categoryData.length) {
      const bookmarkedNewsData = categoryData.map((item) => {
        if (item.url === bookmarkedData.id) {
          const itemData = {
            ...item,
            collectionSourceId: bookmarkedData.collectionSourceId,
            collectionId: bookmarkedData.collectionId,
            isCitationAvailable: bookmarkedData.isCitationAvailable,
            isSummaryAvailable: bookmarkedData.isSummaryAvailable,
          };
          return itemData;
        }
        return item;
      });
      setcategoryData(bookmarkedNewsData);
    }
  }, [
    bookmarkedData.collectionId,
    bookmarkedData.collectionSourceId,
    bookmarkedData.id,
  ]);

  const academiaLabel = academiaSearchList.filter(
    (academia) => academia.categoryName_chr === academiaSearch
  )[0];

  return categoryData.map((row, index) => {
    return (
      <Box
        key={`${row.id}_${index}_${row.name}`}
        sx={{
          display: "grid",
          px: { xs: 0, sm: 1 },
          mb: { xs: 2, sm: "auto" },
          gridTemplateAreas: !isTab ? '"bookmark divide notes"' : '"bookmark"',
          gridTemplateColumns: !isTab ? "70% auto 26%" : "1fr",
          "&:hover .enterNoteField": {
            opacity: 1,
          },
        }}
      >
        <NewsCard
          cardRef={
            categoryData?.length === index + 1 ? lastElementRef : undefined
          }
          data={row}
          key={row.url}
          category={category}
          query={search}
          academiaSearch={academiaSearch}
          academiaLabel={academiaLabel}
        />
        {!isTab && (
          <Divider
            orientation="vertical"
            flexItem
            sx={{ gridArea: "divide", justifySelf: "center" }}
          />
        )}
        {!isTab && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gridArea: "notes",
            }}
          >
            {row.notes != null && row.notes.length > 0 ? (
              <Markdown item={row} note={row.notes[0]} onClick={menuClick} />
            ) : (
              <TextField
                className="enterNoteField"
                sx={{
                  width: "100%",
                  my: "auto",
                  opacity: 0,
                  transition: "all .4s ease",
                }}
                onClick={() => {
                  menuClick(row);
                }}
                label="Enter Note"
                variant="outlined"
              />
            )}
          </Box>
        )}
      </Box>
    );
  });
};

export default SectionCategory;
