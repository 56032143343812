import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { fillParent, scrollingBox, footerFlex } from "utils/base-styles";
import { useNavigate, useParams } from "react-router-dom";
import ToolkitSearchBar from "features/searchbar/toolkitsearchbar";
import DomainCheckerInfo from "features/MisinformationToolkit/components/DomainChecker/DomainCheckerInfo";
import Footer from "components/layout/Footer";
import PageTitle from "components/layout/PageTitle";
import { Stack } from "@mui/system";

export default function DomainCheckerPage() {
  const navigate = useNavigate();
  const { domainName } = useParams();
  function handleSearch(query) {
    navigate(`/tools/domain-checker/${encodeURI(query)}`);
  }

  return (
    <Box
      sx={{
        ...fillParent,
        ...scrollingBox,
        ...footerFlex,
      }}
    >
      <Stack direction="column">
        <Stack direction="column" width={{ xs: "100%", sm: "70%" }}>
          <PageTitle>Domain Checker</PageTitle>
          <Typography variant="caption">
            You no longer have to browse the internet in darkness. Domain
            Checker allows you to discover important qualitative and
            quantitative information about any website on the internet. Our
            independent third party data providers include NewsGuard, AllSides,
            Charity Navigator, Similarweb, and Whois data.
          </Typography>

          <ToolkitSearchBar
            placeholder="Domain to check..."
            handleSearch={handleSearch}
            buttonText="Check"
            value={domainName}
          />
        </Stack>
        {domainName && <DomainCheckerInfo domainName={domainName} />}
      </Stack>
      <Footer />
    </Box>
  );
}
