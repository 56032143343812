import { Stack, Link, Box, Typography, Divider, Tooltip } from "@mui/material";
import NewsguardScore from "components/base/NewsguardScore";
import AllSidesRating from "components/base/all-sides/AllSidesRating";
import { useIsTab } from "hooks/is-tab.hook";
import { useDispatch } from "react-redux";
import {
  setEntityName,
  setIsSourceSidebarOpen,
  setSourceUrl,
} from "context/features/dataSlice";
import { logEvent } from "services/amplitude.service";

export default function TableRow({
  row,
  menuClick,
  isPublicCollectionSrc,
  ...otherProps
}) {
  const isTab = useIsTab();
  const dispatch = useDispatch();

  const openSourceSidebar = () => {
    logEvent("click_card_header", {
      host: row.hostName,
      entity: row.entityName,
    });
    dispatch(setIsSourceSidebarOpen(true));
    dispatch(setSourceUrl(row.hostName));
    dispatch(setEntityName(row.entityName));
  };

  const CardHeader = () => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        onClick={openSourceSidebar}
        sx={{
          borderRadius: 1,
          px: "1rem",
          py: 0.4,
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "#e0e9ef",
          },
        }}
      >
        <Tooltip placement="bottom-start">
          <Typography
            variant="body1"
            sx={{
              fontWeight: "bold",
              width: "20rem",
              cursor: "pointer",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              color: "rgb(126,127,126)",
            }}
          >
            {row.entityName}
          </Typography>
        </Tooltip>
        <Stack direction="row" gap={2}>
          {row.mediaBiasRating && (
            <AllSidesRating activeCategory={row.mediaBiasRating} />
          )}
          {row.score && (
            <Box>
              <NewsguardScore score={Number(row.score)} size="small" />
            </Box>
          )}
        </Stack>
      </Stack>
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
      {...otherProps}
    >
      <CardHeader />
      <Stack
        direction="column"
        gap={1}
        justifyContent="space-between"
        sx={{
          maxWidth: "100%",
          minHeight: "10.2vh",
          gridArea: "bookmark",
          pt: 2,
          px: 1,
        }}
      >
        <Typography
          component="div"
          variant="subtitle1"
          noWrap={!isTab}
          sx={{
            flexGrow: 1,
            minWidth: 0,
            overflowWrap: isTab ? "anywhere" : "unset",
          }}
        >
          <Link href={row.url} target="_blank">
            {row.name}
          </Link>
        </Typography>
        <Stack direction="row" justifyContent="space-between" gap={1}>
          <Box>
            <Typography
              sx={{ width: "100%" }}
              component="span"
              variant="body2"
              color="text.secondary"
            >
              {row.datePublishedDisplayText} - {row.snippet}
            </Typography>
          </Box>
        </Stack>
        <Divider flexItem />
      </Stack>
    </Box>
  );
}
