import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Card,
  Typography,
  CardMedia,
  Tooltip,
  IconButton,
  useTheme,
  useMediaQuery,
  Box,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import {
  setCollectionId,
  setCollectionSourceId,
  setEntityName,
  setIsCitationAvailable,
  setIsSidebarOpen,
  setIsSourceSidebarOpen,
  setIsSummaryAvailable,
  setSelectedBookmarkOption,
  setSourceUrl,
  setSummaryInfoData,
} from "context/features/dataSlice";
import defaultBackground from "assets/images/image-placeholder.png";

import { ReactComponent as CollectionsLogo } from "assets/icons/bookmark-blue-icon.svg";
import { ReactComponent as CollectionsLogoHover } from "assets/icons/bookmark-hover-icon.svg";
import { HiOutlineLockOpen, HiOutlineLockClosed } from "react-icons/hi2";
import NewsguardScore from "components/base/NewsguardScore";
import AllSidesRating from "components/base/all-sides/AllSidesRating";
import { logEvent } from "services/amplitude.service.js";

export const NewsCard = ({ data, query, category }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const openSourceSidebar = () => {
    logEvent("click_card_header", {
      host: data.hostName,
      entity: data.entityName,
    });
    dispatch(setIsSourceSidebarOpen(true));
    dispatch(setSourceUrl(data.hostName));
    dispatch(setEntityName(data.entityName));
  };

  const imageUrl = data?.openGraphImage?.contentUrl
    ? data?.openGraphImage?.contentUrl
    : defaultBackground;

  const CardHeader = ({data}) => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        onClick={openSourceSidebar}
        sx={{
          borderRadius: 1,
          px: "1rem",
          py: 0.4,
          cursor: "pointer",
          "&:hover": {
            backgroundColor: theme.palette.secondary.main,
          },
        }}
      >
        <Tooltip title={data.entityName} placement="bottom-start">
          <Typography
            variant="body1"
            sx={{
              fontWeight: "bold",
              width: "20rem",
              cursor: "pointer",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              color: "rgb(126,127,126)",
            }}
          >
            {data.entityName}
          </Typography>
        </Tooltip>
        <Stack direction="row" gap={2}>
          {data.mediaBiasRating && (
            <AllSidesRating activeCategory={data.mediaBiasRating} />
          )}
          {data.score && (
            <Box>
              <NewsguardScore score={Number(data.score)} size="small" />
            </Box>
          )}
        </Stack>
      </Stack>
    );
  };

  const openSidebar = () => {
    dispatch(setSelectedBookmarkOption("notes"));

    dispatch(setCollectionSourceId(data.collectionSourceId));
    dispatch(
      setSummaryInfoData({
        publisher: data.entityName,
        title: data.name,
        published_date: data.datePublishedDisplayText,
        url: data.url,
      })
    );
    dispatch(setCollectionId(data.collectionId));
    dispatch(setIsCitationAvailable(data.isCitationAvailable));
    dispatch(setIsSummaryAvailable(data.isSummaryAvailable));
    dispatch(setIsSidebarOpen(true));
  };

  const BookmarkOptions = ({data}) => {
    return (
      <Stack
        onClick={() => {
          openSidebar();
          logEvent("open_bookmark_popup", { click: true, url: data.url });
        }}
        className="card-options"
        sx={{
          cursor: "pointer",
          height: "40%",
          pl: 1,
          pr: 1,
          borderRadius: 1,
          transition: "all 0.1s ease",
          "&:hover": {
            backgroundColor: theme.palette.secondary.main,
            "& .collection-hover": {
              opacity: "1 !important",
            },
          },
        }}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        gap={1}
        mt={1}
      >
        {data.collectionSourceId ? (
          <CollectionsLogo
            onClick={openSidebar}
            alt="Collections"
            style={{
              width: 18,
              height: 18,
              fill: "blue",
              color: "blue",
            }}
          />
        ) : (
          <CollectionsLogoHover
            className="collection-hover"
            onClick={openSidebar}
            alt="Collections"
            style={{
              width: 17,
              height: 17,
              fill: "blue",
              color: "blue",
              opacity: 0,
              transition: "all 0.1s ease",
            }}
          />
        )}
        <IconButton
          aria-label="more"
          sx={{
            p: 0,
            "&:hover": {
              backgroundColor: "transparent", // Removing any background change on hover
            },
          }}
        >
          <MoreHorizIcon />
        </IconButton>
      </Stack>
    );
  };

  const ImageSection = () => {
    return (
      <Box
        sx={{
          flexGrow: 1,
          flexBasis: "35%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-end",
          height: "100%",
        }}
      >
        <CardMedia
          component="img"
          sx={{
            width: "100%",
            height: 70,
            objectFit: "cover",
            objectPosition: "center",
            borderRadius: "10px",
          }}
          image={imageUrl}
          alt="No Image Found"
        />
        <BookmarkOptions data={data} />
      </Box>
    );
  };

  const handleTitleClick = () => {
    let newUrl = data.url;
    if (newUrl.includes("foreignpolicy.com")) {
      newUrl = data.url;
      newUrl += "?auth=sooth";
    }

    window.open(newUrl, "_blank");

    logEvent("click_resultset_title", {
      url: newUrl,
      query: query,
      entity: data.entityName,
      category: category,
      mediabiasrating: data.mediaBiasRating ? data.mediaBiasRating : "NA",
    });
  };

  return (
    <Card
      sx={{
        width: "100%",
        height: "100%",
        boxShadow: "none",
        borderRadius: 0,
      }}
    >
      <Stack direction="column" gap={1} justifyContent="space-between">
        <Box sx={{ width: "100%" }}>
          <CardHeader data={data} />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              padding: "0 1rem",
              mt: 1,
            }}
          >
            <Box sx={{ maxHeight: "5em", mb: 2 }}>
              <Typography
                variant="body4"
                onClick={handleTitleClick}
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  lineHeight: "1.5em",
                  maxWidth: "90%",
                  flexShrink: 0,
                  cursor: "pointer",
                  "&:hover": {
                    color: "rgb(18,89,151)",
                  },
                }}
              >
                {data.name}
              </Typography>
            </Box>
            {data.hasPayWall && data.isPartner ? (
              <Tooltip title="We removed the paywall for you!">
                <Box sx={{ cursor: "pointer" }}>
                  <HiOutlineLockOpen />
                </Box>
              </Tooltip>
            ) : (
              data.hasPayWall && (
                <Tooltip
                  placement="bottom-start"
                  title="This source may limit your access without a subscription"
                >
                  <Box sx={{ cursor: "pointer" }}>
                    <HiOutlineLockClosed />
                  </Box>
                </Tooltip>
              )
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "stretch",
            p: "0 1rem",
          }}
        >
          <Stack
            sx={{
              width: "100%",
              flexGrow: 1,
              flexBasis: { xs: "75%", sm: "70%", md: "65%" },
              pr: 2,
              flexShrink: 0,
            }}
          >
            <Box>
              <Typography
                variant={isMobile ? "body4" : "body1"}
                sx={{
                  color: { xs: "black", sm: "grey" },
                  flexGrow: 1,
                  flexBasis: "60%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: { xs: 4, md: 4, lg: 5 },
                  WebkitBoxOrient: "vertical",
                  maxHeight: { xs: "6em", md: "6em", lg: "7.5em" },
                  lineHeight: "1.5em",
                  flexShrink: 0,
                  color: "rgb(126,127,126)",
                }}
              >
                {!isMobile &&
                  data.datePublishedDisplayText &&
                  `${data.datePublishedDisplayText} - `}
                {data.snippet}
              </Typography>
            </Box>
          </Stack>
          <ImageSection />
        </Box>
      </Stack>
    </Card>
  );
};
