import React from "react";
import { Typography, Stack, Link } from "@mui/material";
import SideNavLink from "components/base/SideNavLink";
import { useGetFeedCategories } from "../hooks/get-feed.hook";
import { useDispatch } from "react-redux";
import { setPulseCategoryId } from "context/features/dataSlice";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";

export default function SideMenu() {
  const { feedCategories } = useGetFeedCategories();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLinkClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    navigate("/profile/preferences?id=pulse-category");
  };

  return (
    <React.Fragment>
      <Stack direction="column" gap={1} sx={{ ml: 3, pt: 2 }}>
        <Typography variant="h6" color="#5c6d7e" fontStyle="italic" mt={1.3}>
          Topics
        </Typography>
        <SideNavLink
          style={{ display: "flex", justifyContent: "space-between" }}
          to="/pulse"
        >
          Your Pulse
          <Link component="button" onClick={handleLinkClick}>
            <MoreVertIcon />
          </Link>
        </SideNavLink>
        {feedCategories.map(
          (category) =>
            category.categoryName !== "All" && (
              <SideNavLink
                key={category.categoryId}
                onClick={() =>
                  dispatch(setPulseCategoryId(category.categoryId))
                }
                to={`/pulse/label/${category.categoryName}`}
              >
                {category.categoryName}
              </SideNavLink>
            )
        )}
      </Stack>
    </React.Fragment>
  );
}
