import React from "react";
import { Box, Typography } from "@mui/material";
import NewsguardCriteria from "./NewsGuardCriteria";
import SourceInfoBox from "./SourceInfoBox";
import NewsguardScore from "components/base/NewsguardScore";
import NewsGuardLogo from "components/base/NewsGuardLogo";

export default function NewsguardInfoBox({ data, sx={} }) {
  return (
    <SourceInfoBox
      title="NewsGuard Score"
      header={<NewsguardScore score={data.score} size="large" />}
      buttonText="Full Nutrition Label"
      buttonLink={data.nutritionLink}
      logo={
        <NewsGuardLogo fontSize="2rem" />
      }
      sx={sx}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          gap: 2,
        }}
      >
        <Box
          sx={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography color={"primary.dark"} variant="h6">
            Credibility
          </Typography>
          <NewsguardCriteria
            value={data.falseContent}
            criteria="Does not repeatedly publish false content"
          />
          <NewsguardCriteria
            value={data.basicStandards}
            criteria="Gathers and presents information responsibly"
          />
          <NewsguardCriteria
            value={data.accountabilityPractices}
            criteria="Regularly corrects or clarifies errors"
          />
          <NewsguardCriteria
            value={data.newsOpinion}
            criteria="Handles the difference between news and opinion
                    responsibly"
          />
          <NewsguardCriteria
            value={data.deceptiveHeadlines}
            criteria="Avoids deceptive headlines"
          />
        </Box>
        <Box
          sx={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography color={"primary.dark"} variant="h6">
            Transparency
          </Typography>
          <NewsguardCriteria
            value={data.ownership}
            criteria="Website discloses ownership and financing"
          />
          <NewsguardCriteria
            value={data.labelsAdvertising}
            criteria="Clearly labels advertising"
          />
          <NewsguardCriteria
            value={data.management}
            criteria="Reveals who's in charge, including any possible
                    conflicts of interest"
          />
          <NewsguardCriteria
            value={data.contentCreators}
            criteria="This site provides the names of content creators,
                    along with either contact or bigraphical information"
          />
        </Box>
      </Box>
    </SourceInfoBox>
  );
}
