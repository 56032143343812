import { find, get, map, filter } from "lodash";
import * as yup from "yup";
import { useRef, useState } from "react";
import { Formik } from "formik";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { AddOutlined } from "@mui/icons-material";

import { useGroup } from "../hooks";

import FormikTextField from "components/base/FormikTextField";
import FormikSelectField from "components/base/FormikSelectField";

export default function AddGroupUserModal({ open, handleClose }) {
  const { groupRoles, group, addUser } = useGroup();

  const formRef = useRef();

  const schema = yup.object().shape({
    email: yup.string().email().required().label("Email Address"),
    licenseLevel: yup
      .string()
      .oneOf(
        map(group.licenseLevels, "key"),
        ({ label }) =>
          `${label} must be one of the following values: ${map(
            group.licenseLevels,
            "label"
          ).join(", ")}`
      )
      .label("License Level")
      .required(),
    role: yup
      .string()
      .oneOf(
        map(groupRoles, "key"),
        ({ label }) =>
          `${label} must be one of the following values: ${map(
            groupRoles,
            "label"
          ).join(", ")}`
      )
      .label("Group Role")
      .required(),
  });

  const defaultGroupRole = find(groupRoles, { default: true });
  const [initialState] = useState({
    email: "",
    licenseLevel: get(group.licenseLevels[0], "key")
      ? get(group.licenseLevels[0], "key")
      : "",
    role: defaultGroupRole ? get(defaultGroupRole, "key") : "",
  });

  async function onFormSubmit({ email, licenseLevel, role }) {
    const response = await addUser(email, licenseLevel, role);
    if (response.newUser != null) {
      if (formRef.current) {
        formRef.current.handleReset();
      }
      handleClose();
    }
  }

  return (
    <Formik
      initialValues={{ ...initialState }}
      enableReinitialize={true}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={true}
      onSubmit={onFormSubmit}
      innerRef={formRef}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleReset,
        handleSubmit,
      }) => {
        const { email, licenseLevel, role } = values;
        function resetAndClose(event) {
          handleReset(event);
          handleClose();
        }
        return (
          <Dialog
            open={open}
            onClose={resetAndClose}
            fullWidth
            maxWidth="sm"
            PaperProps={{
              component: "form",
              onSubmit: handleSubmit,
            }}
          >
            <DialogTitle>Add New User</DialogTitle>
            <DialogContent
              sx={{
                overflowY: "revert",
              }}
            >
              <Stack direction="column">
                <FormikTextField
                  fullWidth
                  error={get(errors, "email")}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  value={email}
                  label="Email Address"
                  name="email"
                />
                <Stack direction="row">
                  {group &&
                    group.licenseLevels &&
                    group.licenseLevels.length && (
                      <FormikSelectField
                        error={get(errors, "licenseLevel")}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        value={licenseLevel}
                        label="License Level"
                        name="licenseLevel"
                        id="license-level-select"
                        options={filter(group.licenseLevels, (level) => level.key !== "Unknown")}
                        disabled={group.licenseLevels?.length <= 1}
                        sx={{
                          flex: 1,
                        }}
                      />
                    )}
                  {groupRoles && groupRoles.length && (
                    <FormikSelectField
                      error={get(errors, "role")}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      value={role}
                      label="User Group Role"
                      name="role"
                      id="group-role-select"
                      options={groupRoles}
                      sx={{
                        flex: 1,
                      }}
                    />
                  )}
                </Stack>
              </Stack>
            </DialogContent>
            <DialogActions
              sx={{
                backgroundColor: "secondary.main",
              }}
            >
              <Button variant="outlined" onClick={resetAndClose}>
                Cancel
              </Button>
              <Button startIcon={<AddOutlined />} type="submit">
                Add
              </Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
}
