import React from "react";
import { Box, Typography, Modal, useTheme } from "@mui/material";
import SummarizerIcon from "assets/icons/SummarizerIcon";
import MisinformationFingerprintIcon from "assets/icons/MisinformationFingerprintsIcon";
import MisinformationSearchIcon from "assets/icons/MisinformationSearchIcon";
import DomainCheckerIcon from "assets/icons/DomainCheckerIcon";
import ToolMenuItem from "components/layout/MuiNavbar/ToolMenuItem";

const PopupToolModal = ({ openToolModal, handleCloseModal }) => {
  const theme = useTheme();

  return (
    <Modal
      open={openToolModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
        zIndex: 1300,
      }}
    >
      <Box
        sx={{
          width: "100%",
          position: "absolute",
          bottom: 50,
          bgcolor: "background.paper",
          border: "none",
          borderTopLeftRadius: "3rem",
          borderTopRightRadius: "3rem",
          boxShadow: 0,
          p: 2,
        }}
      >
        <Box sx={{}}>
          <Typography
            variant="h5"
            sx={{
              marginBottom: 1,
              color: theme.palette.grey[700],
              gridColumnStart: "span 6",
            }}
          >
            Research Tools
          </Typography>

          <ToolMenuItem
            title="Summarizer"
            link="/tools/summarizer"
            sx={{
              gridColumnStart: "span 6",
            }}
          >
            <SummarizerIcon />
          </ToolMenuItem>
          <Typography
            variant="h5"
            sx={{
              marginBottom: 1,
              color: theme.palette.grey[700],
              gridColumnStart: "span 6",
            }}
          >
            Misinformation Toolkit
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <ToolMenuItem
              title="Domain Checker"
              link="/tools/domain-checker"
              sx={{
                gridColumnStart: "span 2",
              }}
            >
              <DomainCheckerIcon />
            </ToolMenuItem>
            <ToolMenuItem
              title="Misinformation Fingerprint"
              link="/tools/misinformation-fingerprints"
              sx={{
                gridColumnStart: "span 2",
              }}
            >
              <MisinformationFingerprintIcon />
            </ToolMenuItem>
            <ToolMenuItem
              title="Misinformation Search"
              link="/tools/misinformation-search"
              sx={{
                gridColumnStart: "span 2",
              }}
            >
              <MisinformationSearchIcon />
            </ToolMenuItem>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default PopupToolModal;
